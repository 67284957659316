import Button from 'components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons';

function GuideButton(props) {
    return (
        <Button className='h-10 z-10 md:absolute xl:fixed top-20 right-20' onClick={props.onClick}>
            <span className='whitespace-nowrap space-x-1'>
                <FontAwesomeIcon icon={faStar} />
                <span>Guide</span>
            </span>
        </Button>
    );
}

export default GuideButton;