import FormContainer from "components/FormContainer";
import ConstraintsForm from "./ConstraintsForm";
import KeyResults from "./KeyResults";
import { useTranslation } from "react-i18next";
import GuideForm from "components/GuideForm";
import { useState } from "react";

function KeyResultsForm() {
    const { t } = useTranslation(['problemDefinition']);
    const [guideActive, setGuideActive] = useState(false)
    const text = {
        headline: t("1-kr-hl", "Key Results"),
    }
    const guideText = [
        {
            header: t('1-kr-g1h', 'What is the difference between goals, objectives and key results?'),
            text: t('1-kr-g1t',`A goal is an outcome that is often broad and long-term, like \"becoming No. 1 in the market\" and typically set at the top level of an organization. An objective sets an ambitious, outcome-oriented and motivated direction on how the goal should be reached. It should be inspirational and contribute to one or more of the goals. The key results then help to measure whether the objective has been reached`)
        },
        {
            header: t('1-kr-g2h', 'What is the difference between a result and a constraint?'),
            text: t('1-kr-g2t',`If \"more (or less) is better\" applies, then you are probably talking about a result, e.g., \"take at least third place in the race\". By contrast, a constraint is like a boundary on a racecourse: You need to respect it in order not to get disqualified, but you can use all the space allowed to you, e.g., \"Regulator with no substantial objection to chosen solution\"`)
        },
        {
            header: t('1-kr-g3h', 'Does a problem statement, use case or design goal correspond more to an objective or to a key result?'),
            text: t('1-kr-g3t',`It depends on the context. Often, it will be easier to start at the objective level, and then using key results to make progress measurable. If the problem statement is already very specific and rather narrow, then you might frame it as key result`)
        },
        {
            header: t('1-kr-g4h', 'How many key results should you formulate by objective?'),
            text: t('1-kr-g4t',`Try to be lean in quantity - it will help your team to focus and it will not restrict the solution space too much`)
        },
        {
            header: t('1-kr-g5h', 'How should you formulate your key results?'),
            text: t('1-kr-g5t',`(1) Be specific: State what you want to achieve, e.g., \"increase gross profit\", \"reduce phone response time\", \"increase NPS market ranking\"; (2) Be measurable: How much do you want to achieve, e.g., \"by 50%\", \"down to 15 seconds\", \"by at least 5 positions, minimum rank 5\"; (3) Be realistic: Set results that can reasonably be achieved, e.g., using a market comparison or benchmarks (\"competitor XY did achieve the same success last year\"); (4) Be time-bound: By when should the result be reached? E.g., \"by 2030\", \"within the next 2 years\"; (5) Leave room for creativity: Avoid providing a solution path using language like \"by implementing …\" or \"using …\"; (6) Ensure contribution: Reaching your result should contribute to the corresponding objective, and directly or indirectly to the overall goals of your organization; e.g., \"increase sales by 10%\" would contribute to the goal \"growth\"`)
        },
        {
            header: t('1-kr-g6h', 'Why should key results be quantified?'),
            text: t('1-kr-g6t',`(1) It keeps your team focused and aligned; (2) It thus reduces complexity in your daily business and in your communication; (3) Measurability is made easier; this reduces the probability of later conflict when it comes to evaluation; (4) It creates inspiration and sense, especially if it is directly linked to your organization goals`)
        },
        {
            header: t('1-kr-g7h', 'What if I think quantification makes no sense?'),
            text: t('1-kr-g7t',`Take a few moments to reflect whether a quantified metric could be identified, even for a rather qualitative case (e.g., using percentages, or an index denoting a qualitative concept like democracy, equity, ...). If so, you will need to carefully define the denomination. If this still does not make sense, skip the quantitative part and use well-defined qualitative key results`)
        },
        {
            header: t('1-kr-g8h', 'Why a baseline?'),
            text: t('1-kr-g8t',`It denotes a solid, ideally undisputed starting point - therefore, avoid taking numbers that are prone to change or discussion. It sets the basis for measurability and reduces the probability of later conflict when it comes to evaluation`)
        },
        {
            header: t('1-kr-g9h', 'How to handle multiple key results?'),
            text: t('1-kr-g9t',`While you should limit the number of your key results as much as possible to reduce complexity, there will often be more than one key result. In some trade-off situations, key results can even be potentially conflicting (\"maximize the impact from rate increases while minimizing the churn rate\"). These conflicts should be avoided, e.g., by transforming a key result into a constraint: \"Maximize impact from rate increases while respective a maximum churn threshold of 10%, or vice versa\"`)
        }
    ]


    return (
        <div>
            <GuideForm guideContent={guideText}
                active={guideActive}
                onClose={() => setGuideActive(!guideActive)}
                width={'w-72 xl:w-1/4 2xl:w-1/3'}
                defaultOpen={false} />
            <FormContainer
                title={text.headline}
                width="2xl:w-2/3 xl:w-2/3 md:w-[90%] sm:w-full"
                guide={true}
                openGuide={() => setGuideActive(!guideActive)}
            >
                <KeyResults editActive={true} />
            </FormContainer>
            <div className="mt-24">
                <ConstraintsForm />
            </div>
        </div>
    );
}

export default KeyResultsForm;