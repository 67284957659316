import { useState } from "react";
import Flag from "./Flag";
import Tooltip from "./Tooltip";

/**
 *
 * @param {editActive, name, data, text, color, flagProps} props
 * editActive = status of edit Mode
 * name = of object for updates of parent component
 * data = Array of Objects with >text and >color
 * text =  for label
 * color = for bg color
 * flagProps [optional] = object with styling for border, borderColor, margin
 * @returns A grid with flag components
 */
function FlagCompactGrid({ textSize, tooltip, hoverActive, ...props }) {
    // Flag component is set to be editable by default
    const editActive = (props.editActive === undefined ? true : props.editActive);
    const [selectMode, setSelectMode] = useState(false);
    const selectionUndefined = (props.active === undefined || props.active === null);

    const openSelectMode = () => {
        setSelectMode(true);
        props.onSelectMode?.(true);
    }

    const selectFlag = (itemName) => {
        props.setActiveFlag(itemName);
        setSelectMode(false);
        props.onSelectMode?.(false);
    }

    // Sets active flag to 'undefined' if no flag is ever selected
    const activeItem = props.data.filter(x => x.name === props.active)[0]
    const activeFlag = {
        text: activeItem === undefined ? 'Undefined' : activeItem.text,
        color: activeItem === undefined ? 'bg-gray-200' : activeItem.color,
        style: activeItem === undefined ? 'border-gray-400' : "border-primary-600",
        tooltip: activeItem !== undefined && activeItem.tooltip,
    }


    return (
        <div id={props.id}>
            {(!selectionUndefined && tooltip && activeFlag.tooltip?.length > 0) &&
                <Tooltip
                    className={"-mt-4 transform translate-y-2"}
                    content={activeFlag.tooltip} />
            }
            {/* edit and select mode */}
            {(editActive && (selectMode || selectionUndefined))
                ? (
                    <div className="">
                        {props.data.map((item, index) => (
                            <div className="inline-block" key={index}>
                                <Flag
                                    id={'flag' + index}
                                    index={item.name}
                                    text={item.text}
                                    hoverInfo={item.hoverInfo}
                                    hoverActive={hoverActive}
                                    color={item.color}
                                    style={props.active === item.name ? 'border-primary-600' : 'border-white'}
                                    onClick={() => { selectFlag(item.name) }}
                                    flagProps={props.flagProps}
                                    textSize={textSize}
                                />
                            </div>
                        ))}
                    </div>
                )
                :
                // View and selected Mode 
                (
                    <Flag
                        onClick={!selectionUndefined ? openSelectMode : undefined}
                        text={activeFlag.text}
                        color={activeFlag.color}
                        style={activeFlag.style}
                        flagProps={props.flagProps}
                        cursor={!editActive ? 'default' : undefined}
                        textSize={textSize}
                    />
                )
            }
        </div>
    );
}

export default FlagCompactGrid;