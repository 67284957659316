import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FlagCompactGrid from "components/FlagCompactGrid";
import topicTypesData from "./TopicTypesData";
import ProptTypes from 'prop-types'
import CreateTopicContext from '../../context/TopicContext'

/**
 * Displays flags for topic types
 */
function TopicTypesFlagGrid({ setDefault, tooltip, hoverActive, editActive, textSize }) {
    const ctx = useContext(CreateTopicContext);
    const { t } = useTranslation(['problemDefinition']);
    const itemName = 'type'
    const topicTypes = topicTypesData(t);

    // setting default value
    useEffect(() => {
        if (setDefault && !ctx.data.type) {
            ctx.itemHandler(itemName, "OTHER")
        }
    }, [setDefault]);

    const selectFlag = (data) => {
        ctx.itemHandler(itemName, data)
    }

    return (
        <>
            <FlagCompactGrid
                id="topicTypesFlagGrid"
                data={topicTypes}
                name='topicTypes'
                tooltip={tooltip}
                hoverActive={hoverActive}
                editActive={editActive}
                active={ctx.data?.type}
                setActiveFlag={selectFlag}
                textSize={textSize}
            />
        </>
    );
}

TopicTypesFlagGrid.propTypes = {
    tooltipActive: ProptTypes.bool,
    editActive: ProptTypes.bool,
    activeFlag: ProptTypes.string,
    selectFlag: ProptTypes.func,
    textSize: ProptTypes.string,
}

export default TopicTypesFlagGrid;