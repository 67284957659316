const topicTypesData = (t) => [
    {
        name: 'PROBLEM_STATEMENT',
        text: t('1_ps_tt_l1', 'Problem Statement'),
        color: 'bg-primary-100',
        hoverInfo: t('1_ps_tt_tt1', 'Outcome-focused, specific, measurable, realistic, and time-bound, often in a question form '),
        tooltip: t('1_ps_tt_info1', 'A problem statement should be outcome-focused, specific, measurable, realistic, and time-bound. Often, a question form will be used. Leave room for creativity and align with other parts of your organization \n\nExample: \"How can we decrease the carbon footprint of our school by at least 25% within 1 year compared to the 2020 baseline?\"'),
    },
    {
        name: 'IDEA',
        text: t('1_ps_tt_l2', 'Idea'),
        color: 'bg-primary-100',
        hoverInfo: t('1_ps_tt_tt2', 'Focused, feasible, novel, relevant, systematic, interesting'),
        tooltip: t('1_ps_tt_info2','Be focused, feasible, novel, relevant, systematic, interesting; consider ethical aspects \n\nExample: \"Could we use inspirations from the animal world, e.g., from the social structures of bees or ants, to model financial market participant behaviour?\"'),
    },
    {
        name: 'USE_CASE',
        text: t('1_ps_tt_l3', 'Use Case'),
        color: 'bg-primary-100',
        hoverInfo: t('1_ps_tt_tt3', 'Who is going to use the solution in which way, and what will it be used for'),
        tooltip: t('1_ps_tt_info3','Tell on a high level who is going to use the solution, what it will be used for, and how users are going to use it. \n\nExample: \"An insurer wants to develop an internal search tool helping call center agents reduce response time and improve quality by giving specific, near-time input to advanced customer inquiries\"'),
    },
    {
        name: 'RESEARCH_QUESTION',
        text: t('1_ps_tt_l4', 'Research Question'),
        color: 'bg-primary-100',
        hoverInfo: t('1_ps_tt_tt4', 'Focused, feasible, novel, relevant, systematic, interesting'),
        tooltip: t('1_ps_tt_info4','"Be focused, feasible, novel, relevant, systematic, interesting; consider ethical aspects \n\nExample: \"Which inspirations from the animal world, e.g., from the social structures of bees or ants, could we use to model financial market participant behaviour?\"')
    },
    {
        name: 'POV_STATEMENT_DESIGN_GOAL',
        text: t('1_ps_tt_l5', 'POV Statement & Design Goal'),
        color: 'bg-primary-100',
        hoverInfo: t('1_ps_tt_tt5', 'Who is the user persona, what do they need - provide insights why. \nAsk a question targeted how to address the user needs'),
        tooltip: t('1_ps_tt_info5','Tell who the user persona is, what they needs and provide insights why. Then ask a question leaving room for creativity and stating the design goal in order to address the user needs. \n\nExample: \"A call center agent needs variety w.r.t. to the sentiment and content of customer inquiries because his engagement and thus customer satisfaction will rise with multifaceted work. How then can we increase variety in the work of call center agents?\"')
    },
    {
        name: 'OBJECTIVE',
        text: t('1_ps_tt_l7', 'Objective'),
        color: 'bg-primary-100',
        hoverInfo: t('1_ps_tt_tt7', 'Ambitious, outcome-oriented, inspirational and contributing to one or more of the organizational goals'),
        tooltip: t('1_ps_tt_info7', 'An objective sets an ambitious, outcome-oriented and motivated direction. It should be inspirational and contribute to one or more of the organizational goals.\n\nExample: "Achieve the highest customer satisfaction in the market"')
    },
    {
        name: 'KEY_RESULT',
        text: t('1_ps_tt_l8', 'Key Result'),
        color: 'bg-primary-100',
        hoverInfo: t('1_ps_tt_tt8', 'What and how much you reasonably want to achieve by when. Ensure contribution to a given objective'),
        tooltip: t('1_ps_tt_info8', 'State what and how much you want to achieve and by when; set results that can reasonably be achieved; leave room for creativity and avoid providing a solution path. Ensure that reaching your result contributes to the corresponding objective, and directly or indirectly to the overall goals of your organization \n\nExample: \"Achieve a R-NPS value increase of 5 points by the end of 2024\"'),
    },
    {
        name: 'OTHER',
        text: t('1_ps_tt_l6', 'Other'),
        color: 'bg-primary-100',
    },

];

export default topicTypesData;
