import { useTranslation } from "react-i18next";
import DotMenu from "components/DotMenu";
import VotingArea from "./VotingArea";

/**
 * 
 * @param {title, titleHref, description, className, children
 * sideBar, sideBarContent, sideBarWidth, sideColor, sideBarClass} props 
 * title, tileHref, description are the main values for this card, other content can be integrated
 * through the 'children' prop
 * sideBar is optional, it can be used for displaying initials
 * @returns Card Wrapper with optional Sidebar [activated with 'sideBar={true}']
 */
function CardWrapper({ flags, ...props }) {
    const { t } = useTranslation(['common']);

    const mainContent = <>
        {/* Title */}
        <p className="text-gray-900 font-bold" style={{ maxWidth: `calc(100% - 140px)` }}>{props.title}</p>
        {/* Optional Owner/Creator */}
        {props.owner && <p>{props.owner}</p>}
        {/* Optional Flags */}
        {flags}
        {/* Description */}
        <p className="text-gray-600 whitespace-normal mt-1 max-h-20 overflow-y-scroll pr-2">{props.description}</p></>

    return (
        <div
            id={props.id}
            key={props.key}
            className={`relative col-span-1 flex shadow-sm rounded-md border border-gray-200 ${props.className}`}>
            {/* Optional Sidebar for displaying additional Information like initials */}
            {props.sideBar &&
                <div className={`relative flex-shrink-0 flex pt-4 px-2 z-0 transition-all duration-300 ease-in-out
                    ${props.sideBarWidth || 'w-12'}
                       ${props.sideColor || 'bg-primary-600'}
                       justify-center rounded-l-md`}>
                    <div className={props.sideBarClass || 'text-white font-bold'}>
                        {props.sideBarContent}
                    </div>
                </div>
            }
            {/* Content */}
            <div id="cardWrapperContent"
                className={`w-full ${props.padding || "px-4 py-2"}`}>
                {/* Name with optional link */}
                {props.titleHref
                    ?
                    <a href={props.titleHref}>
                        {mainContent}
                    </a>
                    :
                    <div>
                        {mainContent}
                    </div>
                }
                {props.children}
            </div>
            {/* Top bar with voting and dot-menu*/}
            <div id={'cardWrapperMenu'} className='absolute top-1 right-1 flex align-middle'>
                {props.showVoting &&
                    <div className="pt-1 pr-2 z-10">
                        <VotingArea
                            hoverInfo={true}
                            userVotes={props.userVotes}
                            voteCount={props.count}
                            upVote={props.upVote}
                            upVoteClicked={props.upVoteClicked}
                            downVote={props.downVote}
                            downVoteClicked={props.downVoteClicked} />
                    </div>
                }
                {props.menu ?
                    <div id="dotMenuDiv"
                        className={`flex flex-row-reverse space-x-2 relative mt-0 right-1
                            w-8 h-8 bg-white
                            text-gray-400 rounded-full bg-transparent`}>
                        <DotMenu onClick={props.menuClick} className="w-5 h-5 mx-auto my-auto" aria-hidden="true" />
                    </div>
                    :
                    // Keeps space for styling
                    <div className="w-9 h-9"></div>
                }
            </div>
        </div>
    );
}

export default CardWrapper;