import { useState,useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { getAbbreviation } from 'utils/get-abbrevation'
import CardWrapper from 'components/CardWrapper';
import SimpleDisplayTagList from 'components/SimpleDisplayTagList';
import RoleIcon from 'components/RoleIcon';
import TopicTypesFlagGrid from 'pages/CreateTopicPage/Steps/Components/TopicTypesFlagGrid';
import StateFlag from 'components/SharedElements/StateFlag';
import DeepDiveCollapsableList from './DeepDiveCollapsableList';
import DeepDiveIcon from 'components/SharedElements/DeepDiveIcon';

import ElementWithHoverinfo from 'components/ElementWithHoverinfo';
function ProblemCard({ ...props }) {
    const location = useLocation();
    const { t } = useTranslation(['common', 'problemDefinition']);
    const collapseId = 'collapse' + props.problem.id;
    const [menuActive, setMenuActive] = useState(false);
    const [isDeepDive, setIsDeepDive] = useState(props.problem.isDeepDive)

    const stats = [
        { id: 1, name: t('Participants'), stat: props.problem.contributorsCount },
        { id: 2, name: t('Comments'), stat: props.problem.commentsCount }
    ]

    const problem = {
        id: props.problem.id,
        title: props.problem.title,
        type: props.problem.type,
        isOpen: props.problem.isOpen,
        role: props.problem.role,
        problemDescription: props.problem.statement,
        members: props.problem.members,
        problems: props.problem.problems,
        segmentSelected: props.problem.segmentSelected,
        valueChainSelected: props.problem.valueChainSelected,
        tags: props.problem.tags,
    };

    // const segmentDataList = useSegmentList();
    // const valueChainDataList = useValueChainList();

    function getHref(id) {
        let href = ''
        if (isDeepDive) {
            // Tabview
            href = `${location.pathname}/problems/${id}`
        } else {
            // Topic Overview
            href = `${location.pathname}/topics/${id}`
        }
        return href
    }

    useEffect(() => {
        setIsDeepDive(props.problem.isDeepDive)
    }, [props.problem.isDeepDive]);

    const hoverInfoClass = 'absolute top-0 right-0 scale-0 transition-all rounded bg-primary-100 px-2 py-1 text-sm text-primary-700 group-hover:scale-100 border border-primary-200'

    const sideBarContent = <div className='text-center'>
        <p>{getAbbreviation(problem.title)}</p>
        <p className='mt-2'><RoleIcon role={problem.role} /></p>
    </div>

    const flags = <span className='flex h-8 items-center my-2'>
        <TopicTypesFlagGrid
            editActive={false}
            activeFlag={problem.type}
        />
        <StateFlag isOpen={problem.isOpen} />
    </span>

    // TODO change problem type in DB into 'deep dive' 
    const transformToDeepDive = () => {
        setIsDeepDive(true)
        props.updateProblemById(problem.id, {isDeepDive: true})
    }

    const DeepDiveIconRef = <DeepDiveIcon isDeepDive={isDeepDive} onClick={transformToDeepDive}/>

    return (
        // Link to single problem view with detailed problems 
        <CardWrapper key={props.key} id="problemCard" menuClick={() => setMenuActive(!menuActive)}
            sideBar={true} sideBarContent={sideBarContent}
            title={problem.title}
            titleHref={getHref(problem.id)}
            description={problem.problemDescription}
            flags={flags}
            userVotes={props.userVotes}
            count={props.count}
            showVoting={props.showVoting}
            upVote={props.upVote}
            downVote={props.downVote}
            upVoteClicked={props.upVoteClicked}
            downVoteClicked={props.downVoteClicked}
            sideColor={isDeepDive ? 'bg-green-700' : 'bg-green-400'}
            className=''
        >
            <div className='relative'>
                {/* Classification Tags */}
                <div className='my-2'>
                    {/* market segment tags */}
                    {/* {problem.segmentSelected?.length > 0 &&
                    <div className='group relative'>
                        <FilterAndDisplayTagList
                            dataList={segmentDataList}
                            selectedIdList={problem.segmentSelected}
                            bgColor={'bg-sky-100'}
                            borderColor={'border-sky-200'} />
                        <span className={hoverInfoClass}>
                            {t('area1', 'Market Segment', { ns: 'problemDefinition' })}
                        </span>
                    </div>} */}
                    {/* value chain tags */}
                    {/* {problem?.valueChainSelected?.length > 0 &&
                    <div className='group relative'>
                        <FilterAndDisplayTagList
                            dataList={valueChainDataList}
                            selectedIdList={problem.valueChainSelected}
                            bgColor={'bg-green-100'}
                            borderColor={'border-green-200'} />
                        <span className={hoverInfoClass}>
                            {t('area2', 'Value Chain', { ns: 'problemDefinition' })}
                        </span>
                    </div>} */}
                    {/* tags */}
                    {problem.tags?.length > 0 &&
                        <div className='group relative'>
                            {/* <DisplayTagList
                            list={problem.tags}
                            bgColor={'bg-indigo-100'}
                            borderColor={'border-indigo-200'}
                        /> */}
                            <SimpleDisplayTagList
                                list={problem.tags}
                                handleClick={props.clickTag}
                            />
                            <span className={hoverInfoClass}>
                                {t('1-ps-tags', 'Tags', { ns: 'problemDefinition' })}
                            </span>
                        </div>}
                </div>
                {/* Transform Icon for deep-dives */}
                {/* Wrapped with link if DeepDive active */}
                <div className='absolute bottom-4 right-4'>
                    {isDeepDive ?
                        <a href={getHref(problem.id)}>{DeepDiveIconRef}</a>
                        :
                        <span>{DeepDiveIconRef}</span>
                    }
                </div>
                {/* Stats */}
                <div>
                    <dl className="mt-1 flex justify-left gap-5">
                        {stats.map((item) => (
                            <div key={item.name} className="overflow-hidden rounded-lg bg-white p-2">
                                <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                                <dd className="mt-1 text-2xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
                {/* Collapsable DeepDive List */}
                {isDeepDive && <DeepDiveCollapsableList id={props.problem.id} />}
                {/* Sidemenu for this problem Card */}
                {/* {menuActive &&
                <SideMenu>
                    <SideMenuButton onClick={() => handleEdit(problem.id)}>{t('Edit')}</SideMenuButton>
                    <SideMenuButton onClick={() => handleRemove(problem.id)}>{t('Delete')}</SideMenuButton>
                </SideMenu>
            } */}
            </div>
        </CardWrapper>
    );
}

export default ProblemCard;