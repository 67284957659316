import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CheckIcon } from '@heroicons/react/solid'

// TODO display 'finished' steps ignoring the order // may be done through parent?
function StepsCircles({ maxSteps, ...props }) {
  const steps = props.stepList;

  const class1 = "flex items-center px-3 py-2 text-sm font-medium"
  const class2 = "flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full group-hover:bg-primary-100"
  const stepNameClass = "ml-2 text-xs font-medium text-gray-900"

  return (
    <nav aria-label="Progress" className='pb-2'>
      <ol role="list" className={`divide-y divide-gray-300 rounded-md border border-gray-300 md:border-none md:flex md:divide-y-0
      md:w-2/3 w-full max-w-2xl"}
    `}>
        {props.stepList.map((step, stepIdx) => (
          <li key={step.name} className={`${step.isCurrent && "bg-primary-50 "} relative md:flex md:flex-1 `}>
            <a href={step.href} className={`${step.isCurrent && 'border-primary-300'} group flex w-full items-center border`}>
              <span className={class1}>
                <span className={`${class2}`}>
                  {stepIdx + 1 === maxSteps ?
                    // Overview
                    <span>
                      <FontAwesomeIcon icon={faStar} className='h-5 text-primary-600' />
                    </span>
                    :
                    // All other steps
                    < span className={`${class2} border border-gray-500`}>
                      {step.state === "complete"
                        ? <CheckIcon className="h-6 w-6 text-green-700" aria-hidden="true" />
                        : <span className="text-gray-500 ">{stepIdx + 1}</span>}
                    </span>
                  }
                </span>
                <span className={`${stepNameClass}`}>{step.name}</span>
              </span>
            </a>
          </li>
        ))}
      </ol>
    </nav >
  )
}

export default StepsCircles;