import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import EditableRow from "components/EditableRow";

import TopicContext from "pages/CreateTopicPage/context/TopicContext";

function ConstraintsRow(props) {
    const { t } = useTranslation();
    const ctx = useContext(TopicContext);
    const thisRow = ctx.data.constraints[props.index];
    // const thisRow = props.data;
    const oneRow = ctx.data.constraints?.length === 1;
    const tableName = 'constraints'

    // TODO: make it work with translation
    const [rowData, setRowData] = useState([
        { id: 0, name: "constraint", value: thisRow.constraint, type: "area", style: "" },
        {
            id: 1, name: "level", value: thisRow.level, type: "selectMenu", style: "w-36",
            itemList: [null,
                "LOW",
                "MEDIUM",
                "HIGH"
                // " - ",
                //t("1-c-table-rating1", "Low"),
                //t("1-c-table-rating2", "Medium"),
                //t("1-c-table-rating3", "High")
            ]
        },
        { id: 2, name: "comment", value: thisRow.comment, type: "area", style: "w-52" },
    ])

    const deleteHandler = () => {
        ctx.deleteRowHandler(tableName, thisRow.id);
    }

    const changeHandler = (rowIndex, name, data, itemId) => {
        let tempData = [...rowData];
        tempData[itemId].value = data;
        setRowData(tempData);

        ctx.rowHandler(tableName, name, rowIndex, data)
    }

    return (<EditableRow
        editActive={props.editActive}
        rowId={thisRow.id}
        rowIndex={props.index}
        oneRow={oneRow}
        rowData={rowData}
        changeHandler={changeHandler}
        delete={deleteHandler} />);
}

export default ConstraintsRow;