import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FlagCompactGrid from "components/FlagCompactGrid"
import CreateTopicContext from '../../context/TopicContext';


function ConfidentialFlagGrid({ hoverActive, setDefault, ...props }) {
    const ctx = useContext(CreateTopicContext);
    const { t } = useTranslation(['problemDefinition']);
    const itemName = 'confidentialLevel'
    const confidentialRating = [
        {
            name: 'STRICTLY_CONFIDENTIAL',
            text: t('1-ps-confi1', 'Strictly Confidential'),
            color: 'bg-red-400',
            hoverInfo: t('1-ps-confi1tooltip', 'E.g., medical data, highly sensitive business secrets'),
        },
        {
            name: 'CONFIDENTIAL',
            text: t('1-ps-confi2', 'Confidential'),
            color: 'bg-yellow-400',
            hoverInfo: t('1-ps-confi2tooltip', 'E.g., Personal data, product development documentations'),
        },
        {
            name: 'INTERNAL',
            text: t('1-ps-confi3', 'Internal'),
            color: 'bg-yellow-200',
            hoverInfo: t('1-ps-confi3tooltip', 'E.g., Competitive research not readily available publicly'),
        },
        {
            name: 'PUBLIC',
            text: t('1-ps-confi4', 'Public'),
            color: 'bg-green-200',
            hoverInfo: t('1-ps-confi4tooltip', 'E.g., Marketing material'),
        },
    ]

    // setting default value
    useEffect(() => {
        if(setDefault && !ctx.data.confidentialLevel){
            ctx.itemHandler(itemName, "CONFIDENTIAL")
        }
    }, [setDefault]);

    const selectFlag = (data) => {
        ctx.itemHandler(itemName, data)
    }

    // const changeSelectMode = (value) => {
    //     props.isSelected(value)
    // }

    return (
        <FlagCompactGrid
            id='ConfidenialFlagGrid'
            data={confidentialRating}
            name={itemName}
            hoverActive={hoverActive}
            active={ctx.data.confidentialLevel}
            setActiveFlag={selectFlag}
            editActive={props.editActive}
            // onSelectMode={changeSelectMode}
        />
    );
}

export default ConfidentialFlagGrid;