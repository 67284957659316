import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

const ResizableSplitPanels = ({ panel1, panel2, minLeftWidthPx, minRightWidthPx, minLeftWidth, maxRightWidth }) => {
  const [leftWidth, setLeftWidth] = useState(50);
  const [isResizing, setIsResizing] = useState(false); // New state to track if we're resizing
  const containerRef = useRef(null);
  const bothPanelsActive = panel1 && panel2;
  minLeftWidth = minLeftWidth || 20
  maxRightWidth = maxRightWidth || 70
  minLeftWidthPx = 200;
  minRightWidthPx = 300;


  const startResizing = (mouseDownEvent) => {
    // Prevent default behavior to avoid selecting text while dragging
    mouseDownEvent.preventDefault();
    setIsResizing(true);

    const startX = mouseDownEvent.clientX;     // Calculate the starting width of the left panel as a percentage
    const containerWidth = containerRef.current.offsetWidth;
    const startLeftWidth = (leftWidth / 100) * containerWidth;

    // calcs new width, transforms into %, clamps it betweent 0-100%
    const resize = (mouseMoveEvent) => {
      const newLeftWidthPx = startLeftWidth + (mouseMoveEvent.clientX - startX);
      const newLeftWidthPercent = (newLeftWidthPx / containerWidth) * 100;
      // Calculate the minimum width in percentage for both panels
      const minLeftWidthPercent = (minLeftWidthPx / containerWidth) * 100;
      const minRightWidthPercent = (minRightWidthPx / containerWidth) * 100;
      // Calculate the maximum permissible left width percentage considering the right min width
      const maxLeftWidthPercent = 100 - minRightWidthPercent;
      // Ensure the left width is within the bounds of the minimum and maximum widths
      const boundedLeftWidthPercent = Math.min(
        maxLeftWidthPercent,
        Math.max(minLeftWidthPercent, newLeftWidthPercent)
      );

      setLeftWidth(boundedLeftWidthPercent);
    };
    // Add mousemove and mouseup listeners to the document
    document.addEventListener('mousemove', resize);
    document.addEventListener('mouseup', () => {
      setIsResizing(false);
      document.removeEventListener('mousemove', resize);
    }, { once: true });
  };

  // Only apply the transition classes when not resizing
  const transitionClass = isResizing ? '' : 'transition-all duration-300 ease-in-out';

  return (
    <div ref={containerRef} className="flex h-full">
      {panel1 &&
        <div
          style={{ width: bothPanelsActive ? `${leftWidth}%` : '100%' }}
          className={`${transitionClass} overflow-auto`}
        >
          {panel1}
        </div>}
      {/* Resizer */}
      {bothPanelsActive &&
        <div
          onMouseDown={startResizing}
          className="cursor-col-resize mx-2 select-none flex justify-center"
          style={{ width: '10px' }}
        >
          <div
            className="bg-gray-300 w-1 select-none"
          >
          </div>
        </div>}
      {panel2 &&
        <div
          style={{ width: bothPanelsActive ? `calc(100% - ${leftWidth}%)` : '100%' }}
          className={`${transitionClass} overflow-auto`}
        >
          {panel2}
        </div>}
    </div>
  );
};

ResizableSplitPanels.propTypes = {
  panel1: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  panel2: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
}

export default ResizableSplitPanels;
