import { useEffect } from "react";
import { useTranslation } from "react-i18next"
import RecursiveListItem from "./RecursiveListItem";

/**
 * 
 * @param {list, updateList, name, height}  
 * the list needs an object with id, title, and a 'checked' value 
 * @returns Wrapper for Selectboxes. It is scrollable and resizable by the y-axis
 */
function MultiSelectBoxMultiLevel({ list, updateList, name, height, parentItemTitle }) {
    const { t } = useTranslation(['common']);
    const checkCounter = list ? list.filter(item => item.checked).length : 0
    height = height || 'h-32'
    
    // adds slight bounce whenever the counter is updated
    useEffect(() => {
        const element = document.querySelector('.bubble' + name);
        if (checkCounter > 0) {
            element.classList.remove('scale-100');
            element.classList.add('scale-105');
            setTimeout(() => {
                element.classList.add('scale-100');
                element.classList.remove('scale-105');
            }, 200);
        }
    }, [checkCounter]);

    return (
        <div id="multiSelectBox"
            className={`${height} overflow-y-scroll pl-4 pr-2 py-2 resize-y border border-gray-300 rounded-md transition-all scale-100`}>
            {checkCounter > 0 &&
                <div className={`sticky-top float-right bg-primary-100 border-primary-200 rounded-full p-1 h-7 w-7 flex items-center justify-center ${'bubble' + name}`}
                    style={{
                        animation: "bounce 0.2s ease-in-out",
                    }}>
                    <div className="text-center text-sm font-bold text-primary-800">{checkCounter}</div>
                </div>
            }
            {list.map((item) => (
                <RecursiveListItem
                    parentItemTitle={parentItemTitle}
                    isTopLevelItem={true}
                    key={item.id}
                    item={item}
                    name={name}
                    updateList={updateList}
                />
            ))}
        </div>
    );
}

export default MultiSelectBoxMultiLevel;