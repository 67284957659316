import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import CreateTopicContext from '../../context/TopicContext';

import FlagCompactGrid from "components/FlagCompactGrid";
import ProblemStatementMultiCheck from "../ProblemStatementMultiCheck";
import { parseItem } from 'utils/session-storage';

function VisibilityFlagGrid({ hoverActive, setDefault, ...props }) {
    const ctx = useContext(CreateTopicContext);
    const [communityList, setCommunityList] = useState([]);
    const { t } = useTranslation(['problemDefinition']);
    const itemName = 'visibility'
    const visibilityRating = [
        // {
        //     name: 'INDIVIDUAL_USERS',
        //     text: t('1-ps-visib4', 'Individual'),
        //     color: 'bg-red-400',
        //     hoverInfo: t('1-ps-visib4tooltip', 'Visible for individual users your nominate only'),
        // },
        {
            name: 'SINGLE_COMMUNITY',
            text: t('1-ps-visib3', 'This Community'),
            color: 'bg-yellow-400',
            hoverInfo: t('1-ps-visib3tooltip', 'Visible for members of this community only'),
        },
        {
            name: 'MULTIPLE_COMMUNITIES',
            text: t('1-ps-visib2', 'This and other Communities '),
            color: 'bg-green-200',
            hoverInfo: t('1-ps-visib2tooltip', 'Visible for members of this community and of others you select '),
        },
        // {
        //     name: 'PLATFORM',
        //     text: t('1-ps-visib1', 'Platform'),
        //     color: 'bg-green-200',
        //     hoverInfo: t('1-ps-visib1tooltip', 'Visible for all registered users of this platform'),
        // },
    ]
    const [selectMode, setSelectMode] = useState(null)

    // setting default value
    useEffect(() => {
        if (setDefault && !ctx.data.visibility) {
            ctx.itemHandler(itemName, "SINGLE_COMMUNITY")
        }
    }, [setDefault]);

    useEffect(() => {
        if (props.communities) {
            let arr = []
            props.communities.forEach((item, i) => {
                // excluding current community
                if (parseItem('community')?.id != item.id) {
                    arr.push({
                        id: item.id,
                        text: item.title
                    })
                }
            });
            setCommunityList(arr)
        }

    }, [props.communities])

    const selectFlag = (index) => {
        ctx.itemHandler(itemName, index)
    }

    const changeSelectMode = (value) => {
        setSelectMode(value);
        props.isSelected(value)
    }

    return (
        <>
            <FlagCompactGrid
                id='ConfidenialFlagGrid'
                data={visibilityRating}
                name={itemName}
                hoverActive={hoverActive}
                active={ctx.data.visibility}
                setActiveFlag={selectFlag}
                editActive={props.editActive}
                onSelectMode={changeSelectMode}
            />
            {/* Show selectable list only 'MULTIPLE_COMMUNITIES' is selected */}
            {(ctx.data.visibility === 'MULTIPLE_COMMUNITIES' && !selectMode) &&
                <ProblemStatementMultiCheck
                    ctxName={'communitiesSelected'}
                    name={'communities'}
                    dataList={communityList}
                    selectedIdList={ctx.data.communitiesSelected}
                    viewMode={!props.editActive}
                    height={'h-24'}
                />
            }

        </>
    );
}

VisibilityFlagGrid.propTypes = {
    communities: PropTypes.array,
};

const mapStateToProps = state => ({
    communities: state.communityReducer.communities
});

export default connect(mapStateToProps, null)(VisibilityFlagGrid)