import { XIcon } from '@heroicons/react/outline'
import TextareaAutosize from 'react-textarea-autosize';
import SelectMenu from './SelectMenu';

import React, { useState, useEffect, useRef } from 'react';

const tableInputClassNames = `border-1 border-white 
block sm:text-sm rounded-sm 
 resize-none`

function EditableRow({ noDelete, editActive, intitialMinRow = '1', maxRows = '100', ...props }) {
    const rowData = (props.rowData || [{ id: 0, row: 1 }]);
    const rowRefs = useRef([]);
    const [minRows, setMinRows] = useState(intitialMinRow)
    const rowsCalculated = useRef(false);

    const setRowRef = (index) => (element) => {
        rowRefs.current[index] = element;
    };

    const intialArray = () => {
        let tempArray = [];
        for (let index = 0; index < rowData.length; index++) {
            tempArray.push({ id: index, row: 1 })
        }
        return tempArray;
    }

    const [heightArray, setHeightArray] = useState(intialArray);


    // resizing textareas in intial load
    useEffect(() => {
        const onLoadHandler = () => {
            setTimeout(() => {
                rowRefs.current.forEach((textarea) => {
                    if (textarea && textarea.scrollHeight > 0) {
                        const rowsNeeded = Math.ceil(textarea.scrollHeight / 20);
                        setMinRows((prevMinRows) => Math.max(prevMinRows, rowsNeeded));
                    }
                });
            }, 1000); // needs delay for the textareas to load correctly
        };
        if (document.readyState === 'complete') {
            onLoadHandler();
        } else {
            window.addEventListener('load', onLoadHandler);
        }
        return () => {
            window.removeEventListener('load', onLoadHandler);
        };
    }, []);



    const heightHandler = (event, id) => {
        let tempArray = [...heightArray];
        let currRow = rowCalc(event);
        tempArray[id].row = currRow;
        setHeightArray(tempArray);
        let min = Math.max(...tempArray.map(row => row.row));
        setMinRows(min);
    }

    const rowCalc = (height) => {
        return Math.round((height - 38) / 20) + 1;
    }


    // sends event data to parent, (not just event!)
    const changeHandler = (eventData, name, id) => {
        props.changeHandler(props.rowIndex, name, eventData, id);
        setMinRows(1);
    }

    // select needs to be handled differently because the data isn't stored in event.target.value but just inside 'event' 
    const selectHandler = (data, name, id) => {
        props.changeHandler(props.rowIndex, name, data, id);
    }

    return (
        <tr key={props.rowId} id={"row-" + props.rowId} className="border-b border-gray-200 table-fixed w-full">
            {rowData.map((item, index) => (
                // ${item.type === "selectMenu" && "pt-8px"}
                <td className={`align-top ${item.style} `}
                    key={item.id}>
                    {item.type === "area" &&
                        <TextareaAutosize
                            ref={setRowRef(index)}
                            readOnly={!editActive}
                            cacheMeasurements
                            id={item.id}
                            className={`${tableInputClassNames} ${item.innerStyle || 'w-full'} 
                                        ${editActive
                                    ? 'focus:ring-primary-500 focus:border-primary-500 focus:border-1 hover:border-primary-300'
                                    : 'cursor-auto border focus:ring-white focus:border-white'}`}
                            value={item.value || ''}
                            placeholder="..."
                            minRows={minRows}
                            maxRows={maxRows}
                            onChange={(event) => changeHandler(event.target.value, item.name, item.id)}
                            onHeightChange={(e) => { heightHandler(e, index) }}
                        />
                    }
                    {item.type === "selectMenu" &&
                        (editActive
                            ? <SelectMenu
                                itemList={item.itemList}
                                selected={item.value || item.itemList[0]} // sets the first value as default value if no item is selected
                                onChange={(event) => selectHandler(event, item.name, item.id)}
                                style={'w-full'} />
                            : <label className='sm:text-sm'>{item.value}</label>)
                    }
                    {item.type === 'checkbox' &&
                        <input
                            name={item.name}
                            type='checkbox'
                            checked={item.value}
                            disabled={!editActive}
                            onChange={(e) => changeHandler(e.target.checked, item.name, item.id)}
                            className={`rounded-sm m-auto ${editActive ? 'focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded' : 'bg-gray-200 border-gray-400'} `}

                        />
                    }
                </td>
            ))}
            {!noDelete &&
                <>
                    {(props.oneRow || !editActive) ?
                        <td className='w-16'></td>
                        : <td className='w-16 group text-center cursor-pointer px-2' onClick={props.delete}>
                            <XIcon className='w-5 h-5 m-auto group-hover:text-primary-600 text-gray-500 transform group-hover:scale-125' />
                        </td>}
                </>
            }
        </tr>
    );
}

export default EditableRow;