import { useParams } from "react-router-dom";
import Container from "components/Container";
import TopicDefinitionOverview from "../CreateTopicPage/Overview/TopicDefinitionOverview";
import TopicProvider from "../CreateTopicPage/context/TopicProvider";
import { getProblemById } from "actions/problem.get.actions"

/* Displays the problem definition
    TODO alternative 'create problem definition' button if no problem definition was created previously?
*/
function ProblemDefinitionTab() {

    const { problemId } = useParams();
    
    return (
        <Container id='problemOverviewTabs' maxW='null'>
            {/* <span className='text-xl leading-7'>Problem Overview</span> */}
            <TopicProvider problemId={problemId}>
                <TopicDefinitionOverview title={''}/>
            </TopicProvider>
        </Container>
    );
}

export default ProblemDefinitionTab;