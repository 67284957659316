import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

/**
 * @param {header,text,link,linkText} props 
 * @returns One Item for the Guide
 */
function GuideItem(props) {
    const [active, setActive] = useState(props.defaultOpen);

    return (
        <div>
            <div className="flex justify-between bg-primary-100 rounded p-2 text-base relative select-none cursor-pointer"
                onClick={() => setActive(!active)}>
                <span>{props.header}</span>
                <FontAwesomeIcon
                    className="text-gray-600 my-auto"
                    icon={active ? faChevronUp : faChevronDown}
                />
            </div>
            {active &&
                <div className='p-2'>
                    <div className="text-base whitespace-pre-line">
                        {props.text}
                    </div>
                    {props.link &&
                        <a className='text-primary-700 underline'
                            href={props.link} target="_blank" rel="noreferrer">
                            <p className='pt-4'>
                                {props.linkText}
                            </p>
                        </a>
                    }
                </div>
            }
        </div>
    );
}

export default GuideItem;