import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import Input from "components/InputElement";
import Button from "components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RowDeletable from './RowDeletable';
import FileDownloadableRow from './FileDownloadableRow';

const FileCommentList = forwardRef(({ documentList, setTempFiles, editMode }, ref) => {
    const [fileList, setFileList] = useState([]);
    // const [filesToUpload, setFilesToUpload] = useState([]);
    // const [filesToDelete, setFilesToDelete] = useState([]);
    const [currentFile, setCurrentFile] = useState(null);
    const [currentComment, setCurrentComment] = useState('');

    useEffect(() => {
        if (documentList?.length > 0) {
            setFileList(documentList)
        } else {
            setFileList([])
        }
    }, [documentList])

    // console.log('documentList', documentList)
    // console.log('fileList', fileList)
    // console.log('filesToUpload', filesToUpload)
    // console.log('filesToDelete', filesToDelete)

    const onAdd = () => {
        if (currentFile) {
            const fileWithSameName = fileList.find(f => f.filename == currentFile.name);
            if (!fileWithSameName) {
                const newEntry = {
                    file: currentFile,
                    filename: currentFile.name,
                    text: currentComment,
                    status: 'UPLOAD_PENDING',
                    id: Date.now(),
                };
                const updatedFileList = [...fileList, newEntry];
                setFileList(updatedFileList)
                setTempFiles(updatedFileList)
                setCurrentFile(null)
                setCurrentComment('')
            }

            // if it's realy new
            // if(list.filter(e => e?.filename === newEntry.filename).length === 0 && filesToDelete.filter(e => e?.filename === newEntry.filename).length === 0) {
            //     let updatedFilesToUpload = new Set(filesToUpload)
            //     updatedFilesToUpload.add(newEntry)
            //     setFilesToUpload(Array.from(updatedFilesToUpload))
            //     setUploadFiles(Array.from(updatedFilesToUpload))
            // }
        }
    };

    const handleDelete = (id, filename) => {
        const idx = fileList.findIndex(entry => entry.id == id);

        if (['UPLOAD_PENDING', 'UPLOAD_ERROR'].includes(fileList[idx]?.status)) {
            const updatedFileList = fileList.filter(entry => entry.id !== id)
            setFileList(updatedFileList)
            setTempFiles(updatedFileList)
        } else {
            const updatedFileList = fileList.filter(entry => entry.id !== id)
            setFileList(updatedFileList)

            let updatedTmpFileList = [...fileList] 
            updatedTmpFileList[idx].status = 'DELETE_PENDING';
            setTempFiles(updatedTmpFileList)
        }

        // // if it already was in  the list
        // if(list.filter(e => e?.filename === filename).length > 0 && filesToUpload.filter(e => e?.filename === filename).length === 0) {
        //     let updatedFilesToDelete = new Set(filesToDelete)
        //     updatedFilesToDelete.add(filename)
        //     setFilesToDelete(Array.from(updatedFilesToDelete))
        //     setDeleteFiles(Array.from(updatedFilesToDelete))
        // }
    };

    const handleFileChange = (e) => {
        setCurrentFile(e.target.files[0]);
    };

    const handleCommentChange = (e) => {
        setCurrentComment(e.target.value);
    };

    // Expose resetList function to parent component via ref
    useImperativeHandle(ref, () => ({
        resetList() {
            setFileList([]);
            setCurrentFile(null);
            setCurrentComment('');
        }
    }));

    return (
        <div id="fileCommentList">
            {fileList?.length > 0 && (
                <ul>
                    {fileList.map(({ id, filename, text, link, status }) => (
                        <li key={id} className="">
                            {editMode ? 
                                status !== 'DELETE_PENDING' ?
                                <RowDeletable editMode={editMode} id={id} filename={filename} handleDelete={handleDelete}>
                                    <span className='w-48'>{filename}</span>
                                    <span>{text}</span>
                                </RowDeletable>
                                : null
                            :
                            <FileDownloadableRow filename={filename} text={text} link={link} status={status} />
                            }
                        </li>
                    ))}
                </ul>
            )}
            {editMode && <div className="flex space-x-1 items-center">
                <input
                    key={fileList?.length}
                    type="file"
                    onChange={handleFileChange}
                />
                <Input
                    id={'fileComment'}
                    value={currentComment}
                    onChange={handleCommentChange}
                    placeholder="Add a comment (optional)"
                />
                <Button onClick={onAdd} disabled={!currentFile} className="w-[14px] p-2">
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
            </div>}
        </div>
    );
});

export default FileCommentList;
