import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OverviewRowModule from './OverviewRowModule';
import CreateTopicContext from '../context/TopicContext';
import VisibilityFlagGrid from '../Steps/Components/VisibilityFlagGrid';

import {
    faCommentDots, faEdit, faSave, faTimes, faTrash, faUserGroup, faUserPlus,
    faWrench
} from '@fortawesome/free-solid-svg-icons';

import FormContainer from 'components/FormContainer';
import Taglist from 'components/Taglist/Taglist';
import DisplayTagList from 'components/DisplayTagList';
import CommentOverlay from 'components/Comment/CommentIndex';
import SimpleGroupHover from 'components/SimpleGroupHover';
import LinkList from 'components/LinkList';

import { ButtonAi } from 'components/ButtonAi';
import ResizableSplitPanels from 'components/ResizableSplitPanels';
import { ResizablePanelRight } from 'components/ResizablePanelRight';
import VertexForm from 'components/VertexForm/VertexForm';
import MultiSelectBoxMultiLevel from 'components/MultiSelectBoxMultiLevel';
import Input from 'components/InputElement';
import TeamTable from 'components/TeamTable';
import Modal from 'components/Modal';
import ToggleElement from 'components/ToggleElement';
import FileCommentList from 'components/FileCommentList';

import { parseItem } from 'utils/session-storage';
import { flatArrayForSelected } from 'utils/flat-array-for-selected';
import { changeSelectedIdList as changeIdList } from "utils/change-selected-id-list";
import { updateCheckitemState } from "utils/update-checkitem-state";

import { getProblemById } from 'actions/problem.get.actions';
import { updateProblemById } from 'actions/problem.update.actions';
import { deleteProblemById } from 'actions/problem.delete.actions';
import { uploadFile } from 'actions/file.upload.actions';
import { deleteFile } from 'actions/file.delete.actions';
import StateFlag from 'components/SharedElements/StateFlag';
import ElementWithHoverinfo from 'components/ElementWithHoverinfo';
import ConfidentialFlagGrid from '../Steps/Components/ConfidentialFlagGrid';
import CriticalDefinitionsTable from '../Steps/CriticalDefinitions/CriticalDefinitionsTable';
import StakeholdersTable from '../Steps/Stakeholders/StakeholdersTable';
import RatingFlagGrid from '../Steps/Rating/RatingFlagGrid';
import KeyResults from '../Steps/KeyResultsConstraints/KeyResults';
import ConstraintsTable from '../Steps/KeyResultsConstraints/ConstraintsTable';
import { alertActionTypes } from 'constants/alert.action.types';

import { filterValueChains } from 'pages/UserProfilePage/ProfileForm';
import TopicTypesFlagGrid from '../Steps/Components/TopicTypesFlagGrid';
import DeepDiveIcon from 'components/SharedElements/DeepDiveIcon';

function TopicDefinitionOverview({
    problem,
    editOnly,
    profile,
    getProblemById,
    updateProblemById,
    deleteProblemById,
    commentDefaultOpen,
    handleValidation,
    currentCommunity,
    uploadFile,
    deleteFile,
    tabview,
}) {
    const dispatch = useDispatch();
    const history = useHistory();

    const newTopic = (editOnly || false); // true for new Problem, to hide share/edit button
    const [editModeActive, setEditModeActive] = useState(newTopic ? true : false); // switch for Product 'Problem Definition' 

    const { t, i18n } = useTranslation(['common', 'problemDefinition', 'user']);

    const [problemData, setProblemData] = useState({ users: [] });
    const [isDeepDive, setIsDeepDive] = useState(false)

    useEffect(() => {
        if (problem) {
            setProblemData(problem)
            setIsDeepDive(problem.isDeepDive)
        }
    }, [problem]);

    const text = {
        header: "",
        visibility: t('1-ov-visib', "Visibility", { ns: 'problemDefinition' }),
        documents: t('1-ps-docs', 'Related Documents'),
        title: t('1-ps-title', "Title & Type", { ns: 'problemDefinition' }),
        statement: t('1-ps-prompt', "Topic & State ", { ns: 'problemDefinition' }),
        linkList: t('1-ps-linkList', 'Related Links', { ns: 'problemDefinition' }),
        situation: t('1-ov-sit', 'Situation', { ns: 'problemDefinition' }),
        complications: t('1-ov-com', 'Complications', { ns: 'problemDefinition' }),
        segment: t('area1', 'Market Segment', { ns: 'problemDefinition' }),
        valueChain: t('area2', 'Value Chain', { ns: 'problemDefinition' }),
        tags: t('1-ps-tags', 'Tags', { ns: 'problemDefinition' }),
        inValidMessage: t('required-hoverInfo1', 'Please fill in all required fields and select a community.'),
        topicState: [t('1_ps_topicState1', 'Open', { ns: 'problemDefinition' }), t('1_ps_topicState2', 'Implemented', { ns: 'problemDefinition' })],
        confidentialTitle: t('1-ps-conf', 'Confidentiality'),
        criticalDefinitions: t('1-cd-hl', "Critical Definitions"),
        stakeholders: t('1-sh-hl', "Stakeholders"),
        rating: t('1-pr-hl', "Problem Rating"),
        keyResults: t("1-kr-hl", "Key Results"),
        constraints: t("1-c-hl", "Constraints"),
    }

    const ctx = useContext(CreateTopicContext);

    const [commentActive, setCommentActive] = useState(commentDefaultOpen)
    const [vertexActive, setVertexActive] = useState(false)
    const [vertexContext, setVertexContext] = useState('')
    //TODO check for stored width for comment, use default width if nothing is found, default: 480px
    const [vertexRightPosition, setVertexRightPosition] = useState('right-[480px]')

    const { projectId, problemId } = useParams();

    const editActiveHandler = () => {
        setEditModeActive(!editModeActive);
        setCommentActive(false);
        getProblemById(problemId);
    }

    const [industryList, setIndustryList] = useState([])
    const [segmentList, setSegmentList] = useState([]);
    const [valueChainList, setValueChainList] = useState([]);
    const [currentTopicState, setCurrentTopicState] = useState();

    const deepTopicLink = `/clusters/${projectId}/problems/new?topicId=${problemId}`

    // updates internal segmentList with the checked objects
    useEffect(() => {
        if (ctx.segmentListData?.length > 0) {
            const newSegmentList = ctx.segmentListData.map(item => updateCheckitemState(item, ctx.data.segmentSelected || []));
            setSegmentList(newSegmentList);
            setIndustryList(newSegmentList.filter(item => item?.checked).map(item => item?.industry));
        }
    }, [ctx.segmentListData, ctx.data?.segmentSelected]);

    // updates internal valueChainList with the checked objects
    // filter Value Chains either based on selected parents in Segments or previously selected Value Chains
    useEffect(() => {
        let filteredList = filterValueChains(ctx.valueChainListData, ctx.data.valueChainSelected, industryList);
        const newValueList = filteredList.map(item => updateCheckitemState(item, ctx.data.valueChainSelected || []));
        setValueChainList(newValueList);
    }, [ctx.valueChainListData, ctx.data.valueChainSelected, industryList]);

    const [isValid, setIsValid] = useState({
        title: true,
        statement: true,
        confidential: true,
        linkInput: true
    });

    const [documentValid, setDocumentValid] = useState(null)
    const [canEditAndDelete, setCanEditAndDelete] = useState(false);

    const handleVertexClick = (context, contextName) => {
        if (vertexActive) {
            setVertexContext('')
            setVertexActive(false)
        } else {
            setVertexContext(context)
            setVertexActive(true)
        }
    }

    // organisation role
    useEffect(() => {
        let currentOrganisation = parseItem('organisation')
        if (currentOrganisation && currentOrganisation.role) {
            if (currentOrganisation.role === 'OWNER' || currentOrganisation.role === 'ADMIN') {
                setCanEditAndDelete(true)
            }
        }
    }, []);

    // community role
    useEffect(() => {
        let currentCommunity = parseItem('community')
        if (currentCommunity && currentCommunity.role) {
            if (currentCommunity.role === 'OWNER' || currentCommunity.role === 'ADMIN') {
                setCanEditAndDelete(true)
            }
        }
    }, []);

    useEffect(() => {
        if (isValid.title && isValid.statement && isValid.confidential && isValid.linkInput) {
            setDocumentValid(true)
            handleValidation && handleValidation(true)
        } else {
            setDocumentValid(false)
            handleValidation && handleValidation(false)
        }
    }, [handleValidation, isValid]);

    // sets initial 'state open' true when no state is set in the DB
    useEffect(() => {
        if (ctx.data.isOpen === null) {
            ctx.itemHandler('isOpen', true)
            setCurrentTopicState(true)
        } else {
            setCurrentTopicState(ctx.data.isOpen)
        }
    }, [currentTopicState, ctx.data.isOpen]);

    // updates the context data and the validation for the fields
    const changeAndUpdateValidation = (event, fieldName) => {
        ctx.itemHandler(fieldName, event.target.value)
        setIsValid({ ...isValid, [fieldName]: event.target.value?.length > 0 })
    }

    const handleConfidentialForValidation = (state) => {
        setIsValid({ ...isValid, confidential: !state })
    }

    const handleSave = (e) => {
        e.preventDefault()

        const documentUploadList = ctx.data.documentList?.filter(t => t.status === 'UPLOAD_PENDING');
        const documentDeleteList = ctx.data.documentList?.filter(t => t.status === 'DELETE_PENDING');
        const documentKeepList = ctx.data.documentList?.filter(t => t.status !== 'UPLOAD_PENDING' && t.status !== 'DELETE_PENDING');

        syncDocuments(documentKeepList, documentUploadList, documentDeleteList)
    };

    // sync documents
    function syncDocuments(documentKeepListOrig, documentUploadList, documentDeleteList) {
        const uploadPromises = documentUploadList?.map(doc =>
            uploadFile(problemId, doc.filename, doc.file)
                .then(response => {
                    return Promise.resolve({ ...doc, status: 'UPLOADED', response: response });
                })
                .catch(error => {
                    return Promise.reject({ ...doc, status: 'UPLOAD_ERROR', response: error });
                })
        );

        const deletePromises = documentDeleteList?.map(doc =>
            deleteFile(problemId, doc.filename)
                .then(response => {
                    return Promise.resolve({ ...doc, status: 'DELETED', response: response });
                })
                .catch(error => {
                    return Promise.reject({ ...doc, status: 'DELETE_ERROR', response: error });
                })
        );

        let data = { ...ctx.data }
        delete data['documentTmpList']

        // Wait for all uploads to complete and return the updated document list
        Promise.allSettled(uploadPromises.concat(deletePromises)).then(results => {

            const fulfilled_upload = results.filter(result => result.status === 'fulfilled' && result?.value?.status === 'UPLOADED').map(result => result?.value);
            const fulfilled_delete = results.filter(result => result.status === 'fulfilled' && result?.value?.status === 'DELETED').map(result => result?.value);
            const rejected_upload = results.filter(result => result.status === 'rejected' && result?.reason?.status === 'UPLOAD_ERROR').map(result => result?.reason);
            const rejected_delete = results.filter(result => result.status === 'rejected' && result?.reason?.status === 'DELETE_ERROR').map(result => result?.reason);

            // excluding from keep list DELETED files
            const documentKeepList = documentKeepListOrig?.filter((d) => {
                return fulfilled_delete?.every((t) => {
                    return t.id !== d.id;
                });
            });

            const documentList = documentKeepList.concat(fulfilled_upload, rejected_upload, rejected_delete)
            ctx.itemHandler('documentList', documentList);


            data['documentList'] = documentList
            updateProblemById(problemId, data).then(
                response => {
                    setEditModeActive(!editModeActive)
                    if (rejected_upload?.length > 0 || rejected_delete?.length > 0) {
                        dispatch({ type: alertActionTypes.ERROR, payload: 'some files could not be uploaded / deleted, please redo' })
                    }

                })
        });
    }

    const transformToDeepDive = () => {
        updateProblemById(problemId, { isDeepDive: true }).then(
            setIsDeepDive(true)
        )
    }

    const handleDelete = (e) => {
        e.preventDefault()
        deleteProblemById(problemId).then(() => {
            history.push(`/clusters/${projectId}`)
        })
    };

    const handleUrlInputActive = (state) => {
        setIsValid({ ...isValid, linkInput: !state })
    }

    // updates formData with new Checked id
    const changeSegmentList = (item, checked) => {
        ctx.itemHandler('segmentSelected', changeIdList(ctx.data.segmentSelected, ctx.segmentListData, item, checked))
    };

    const changeValueChainList = (item, checked) => {
        ctx.itemHandler('valueChainSelected', changeIdList(ctx.data.valueChainSelected, ctx.valueChainListData, item, checked))
    };

    const addUser = (user) => {
        console.log('requested user add')
    }

    const handleStateChange = (id, stateIsClosed) => {
        ctx.itemHandler('isOpen', stateIsClosed)
        setCurrentTopicState(stateIsClosed)
    }

    const selectFlag = (index) => {
        ctx.itemHandler('type', index)
    }

    return (
        <div>
            <Modal id='modalTeam'
                size='modal-xl'
                backdrop>
                <TeamTable
                    showOrganisationRoles
                    showCommunityRoles
                    showProjectRoles
                    showProblemRoles
                    showLastLogin
                    users={problemData?.users}
                />
            </Modal>
            <Modal id='addMember'
                size='modal-xl'
                backdrop>
                <TeamTable
                    // showAddButton
                    users={problemData?.relevantProfiles}
                    addUser={addUser}
                />
            </Modal>
            <div>
            </div>
            {/* Split panel for AI and comments */}
            <ResizablePanelRight active={vertexActive || commentActive}
                name="topicOverviewPanel"
                topPosition={'top-[70px]'}
                height={'calc(100% - 70px)'}
                className={'overflow-hidden'}
                minWidth='400'
                disableCloseButton={true}>
                <ResizableSplitPanels
                    panel1={vertexActive ?
                        <VertexForm context={vertexContext}
                            closeButton={true}
                            onClose={() => setVertexActive(false)} />
                        : false}
                    panel2={commentActive ?
                        <CommentOverlay
                            active={commentActive}
                            profile={profile}
                            parentId={problemId}
                            onClose={() => setCommentActive(false)} />
                        : false}
                >
                </ResizableSplitPanels>
            </ResizablePanelRight>

            <div id='problemOverview'
                style={{ width: commentActive ? '65%' : '', maxWidth: '1100px' }}
                className={`${!commentActive && 'w-full'} transition-all duration-300 ease-in-out`}>
                {/* Refine Button for transformed topics*/}
                {/* TODO remove refine button once topic is 'refined' */}
                <div className='pl-4 pb-4'>
                    {isDeepDive &&
                        <Link to={deepTopicLink}>
                            <ElementWithHoverinfo
                                button
                                id='refineButtonOverview'
                                bold
                                buttonContent={<><FontAwesomeIcon className='h-4 w-4 pr-2' icon={faWrench} />
                                    <span>{t('1-ov-refine', 'Refine', { ns: 'problemDefinition' })}</span></>}
                                tooltip={t('1-ov-ref-tool', '[Placeholder] Refine your Topic ...', { ns: 'problemDefinition' })}
                            />
                        </Link>
                    }
                </div>
                {/* Button Area Start */}
                <div id='buttonArea' className='float-right my-0 space-x-6 flex mb-4'>
                    {!newTopic && (ctx.data.role === 'OWNER' || canEditAndDelete) &&
                        <>
                            {/* Deep Topic Button */}
                            {/* TODO change function to transform into deep dive, delete link if wanted */}
                            {!isDeepDive && <DeepDiveIcon className={'pr-8'} onClick={transformToDeepDive} />}
                            {/* Save / Edit Button */}
                            <SimpleGroupHover
                                color={!documentValid && 'red'}
                                position={documentValid ? "-bottom-10" : 'top-8'}
                                text={editModeActive ? (documentValid ? t('Save') : text.inValidMessage) : t('Edit')}
                            >
                                <FontAwesomeIcon
                                    id='problemDefinition-toggleEditActive-btn'
                                    className={`h-5 
                                    ${documentValid ? 'text-primary-700 cursor-pointer' : 'text-gray-200 cursor-not-allowed'}`}
                                    onClick={editModeActive ? (documentValid ? handleSave : null) : editActiveHandler}
                                    icon={editModeActive ? faSave : faEdit} />
                            </SimpleGroupHover>
                            {/* Cancel */}
                            {editModeActive &&
                                <ElementWithHoverinfo
                                    active
                                    id='cancelTopicEdit'
                                    tooltip={t('Cancel')}
                                    icon={faTimes}
                                    onClick={editActiveHandler}
                                />}

                            {/* Delete */}
                            {!tabview && <ElementWithHoverinfo
                                id='deleteTopic'
                                active
                                iconColor='text-red-700'
                                icon={faTrash}
                                tooltip={t('Delete')}
                                onClick={handleDelete}
                            />}
                        </>
                    }
                    {/* Visible to all users */}
                    {!editModeActive &&
                        <>
                            {/* Team Table */}
                            <ElementWithHoverinfo
                                id='teamMember'
                                modal='#modalTeam'
                                icon={faUserGroup}
                                active={problemData?.users?.length > 0}
                                number={problemData?.users?.length}
                                tooltip={t('Team')}
                            />
                            {/* Add Member */}
                            <ElementWithHoverinfo
                                id='addMember'
                                icon={faUserPlus}
                                tooltip={t('Add Member')}
                                active={problemData?.relevantProfiles?.length > 0}
                                number={problemData?.relevantProfiles?.length}
                                modal={'#addMember'}
                            />
                            {/* Comment Switch */}
                            <ElementWithHoverinfo
                                id='commentSwitchBtn'
                                onClick={() => setCommentActive(!commentActive)}
                                active={!commentActive}
                                icon={faCommentDots}
                                tooltip={!commentActive && t('Comments')}
                            />
                        </>
                    }
                </div>
                {/* End of Button Area */}

                {!newTopic && <div id='problemTitle' className='float-left ml-4'>
                    <div className='font-bold text-lg flex items-center'>
                        <span className='mr-2'>{ctx.data.title}</span>
                        {/* Topic Type View Mode*/}
                        {!editModeActive && !tabview &&
                            <div className={`flex items-center ${ctx.data?.title?.length > 80 ? 'flex-col justify-center' : 'flex-row space-x-1'}`}>
                                <TopicTypesFlagGrid
                                    tooltipActive
                                    editActive={false}
                                    activeFlag={ctx.data?.type}
                                    textSize='text-xs font-medium'
                                />
                                <StateFlag isOpen={ctx.data.isOpen} />
                            </div>
                        }
                    </div>
                    {/* Created by */}
                    {ctx.data.createdBy && ctx.data.createdBy.userId &&
                        <Link to={`/profiles/${ctx.data.createdBy.userId}`}>
                            <p className='text-gray-600 text-sm'>{t('Created by')} <span className='font-bold'>{ctx.data.createdBy && ctx.data.createdBy.firstName} {ctx.data.createdBy && ctx.data.createdBy.lastName}</span></p>
                        </Link>
                    }
                </div>}


                <FormContainer title={text.header} width="w-full">
                    <div className="">
                        {editModeActive &&
                            <OverviewRowModule index='title'
                                label={text.title}
                                value={ctx.data.title}
                                onChange={(e) => changeAndUpdateValidation(e, 'title')}
                                type="area"
                                editActive={editModeActive}
                                required
                                className={'font-bold'}
                            />}
                        <p className='text-black'>{documentValid}</p>
                        {/* Topic Type Flags, edit mode*/}
                        {editModeActive &&
                            <div className='-mt-6'>
                                <OverviewRowModule
                                    index='topicTypes'
                                    type="other"
                                    editActive={editModeActive}
                                >
                                    <TopicTypesFlagGrid
                                        tooltipActive={true}
                                        editActive={true}
                                        activeFlag={ctx.data?.type}
                                        selectFlag={selectFlag}
                                        setDefault
                                    />
                                </OverviewRowModule>
                            </div>}
                        {/* Statement & Topic Status [open/implemented]*/}
                        <div>
                            <div className='relative'>
                                <OverviewRowModule index='statement'
                                    label={text.statement}
                                    value={ctx.data.statement}
                                    onChange={(e) => changeAndUpdateValidation(e, 'statement')}
                                    type="area"
                                    editActive={editModeActive}
                                    required
                                />
                                <ButtonAi onClick={() => handleVertexClick(ctx.data.statement, 'statement')} />
                            </div>
                            {/* Topic State */}
                            {editModeActive &&
                                <div className='-mt-4'>
                                    <OverviewRowModule
                                        index='topicState'
                                        type='other'
                                        editActive={editModeActive}
                                    >
                                        <ToggleElement
                                            rightLabel={currentTopicState ? text.topicState[0] : text.topicState[1]}
                                            firstColor={'bg-yellow-500'}
                                            secondColor={'bg-green-500'}
                                            activated={!currentTopicState}
                                            toggleState={handleStateChange}
                                        />
                                    </OverviewRowModule>
                                </div>}
                        </div>
                        {/* Related Documents */}
                        {(currentCommunity?.config?.isProblemDocuments && (editModeActive || ctx.data?.documentList?.length > 0)) &&
                            <OverviewRowModule
                                label={text.documents}
                                type="other"
                                editActive={editModeActive}
                            >
                                <FileCommentList
                                    editMode={editModeActive}
                                    documentList={ctx.data.documentList}
                                    setTempFiles={(data) => ctx.itemHandler('documentList', data)}
                                />
                            </OverviewRowModule>}
                        {/* Linklist Row */}
                        {currentCommunity?.config?.isProblemLinks &&
                            <OverviewRowModule
                                label={text.linkList}
                                type="other"
                                editActive={editModeActive}
                            >
                                <LinkList
                                    editMode={editModeActive}
                                    list={ctx.data.linkList}
                                    changeList={(data) => ctx.itemHandler('linkList', data)}
                                    urlInputActive={handleUrlInputActive}
                                />
                            </OverviewRowModule>
                        }
                        {/* Visibility Row */}
                        <OverviewRowModule index='visibility'
                            label={text.visibility}
                            type="other"
                            editActive={editModeActive}
                            required
                        >
                            <VisibilityFlagGrid
                                editActive={editModeActive}
                                tooltipActive
                                isSelected={handleConfidentialForValidation}
                                setDefault
                            />
                        </OverviewRowModule>
                        {/* // TODO handle valid form for confidentiality #86c0ccx6m*/}
                        {/* Confidentiality */}
                        {isDeepDive &&
                            <OverviewRowModule index='confidentiality'
                                label={text.confidentialTitle}
                                type='other'
                                editActive={editModeActive}
                                required
                            >
                                <ConfidentialFlagGrid
                                    hoverActive={true}
                                    editActive={true}
                                    activeFlag={ctx.data.confidentialLevel}
                                    setDefault
                                />
                            </OverviewRowModule>}
                        {/* Taglist */}
                        {currentCommunity?.config?.isProblemTags &&
                            <OverviewRowModule index='tags'
                                label={text.tags}
                                // value={ctx.data.}
                                // onChange={ctx.handleChange('tags')}
                                type="other" editActive={editModeActive}
                            >
                                {editModeActive ?
                                    <Taglist
                                        value={ctx.data.tags}
                                        onChange={(data) => ctx.itemHandler('tags', data)}
                                    />
                                    :
                                    <DisplayTagList
                                        list={ctx.data.tags}
                                        bgColor={'bg-indigo-100'}
                                        borderColor={'border-indigo-200'} />
                                }
                            </OverviewRowModule>
                        }
                        {/* Segments */}
                        {currentCommunity?.config?.isProblemSegments &&
                            <OverviewRowModule index='segments'
                                label={text.segment}
                                type="other" editActive={editModeActive}
                            >
                                {editModeActive ?
                                    <Input
                                        id={'interestsSegment'}
                                        label={null}
                                        other={true}>
                                        <MultiSelectBoxMultiLevel
                                            list={segmentList}
                                            updateList={changeSegmentList}
                                            name={'interestsSegment'}
                                        />
                                    </Input>
                                    :
                                    <DisplayTagList
                                        list={flatArrayForSelected(segmentList, ctx.data.segmentSelected)}
                                        bgColor={'bg-sky-100'}
                                        borderColor={'border-sky-200'}
                                    />
                                }
                            </OverviewRowModule>
                        }
                        {/* Value Chains */}
                        {currentCommunity?.config?.isProblemValueChains &&
                            <OverviewRowModule index='valueChains'
                                label={text.valueChain}
                                type="other" editActive={editModeActive}
                            >
                                {editModeActive ?
                                    <Input
                                        id={'interestsValueChain'}
                                        label={null}
                                        other={true}>
                                        <MultiSelectBoxMultiLevel
                                            list={valueChainList}
                                            updateList={changeValueChainList}
                                            name={'interestsValueChain'}
                                        />
                                    </Input>
                                    :
                                    <DisplayTagList
                                        list={flatArrayForSelected(valueChainList, ctx.data.valueChainSelected)}
                                        bgColor={'bg-sky-100'}
                                        borderColor={'border-sky-200'}
                                    />
                                }
                            </OverviewRowModule>
                        }
                        {/* Situation */}
                        {currentCommunity?.config?.isProblemSituation &&
                            <OverviewRowModule index='situation'
                                label={text.situation}
                                value={ctx.data.situation}
                                onChange={ctx.handleChange('situation')}
                                type="area"
                                editActive={editModeActive}
                            />
                        }
                        {/* Complications */}
                        {(currentCommunity?.config?.isProblemComplications && (editModeActive || ctx.data?.complications)) &&
                            <OverviewRowModule index='complications'
                                label={text.complications}
                                value={ctx.data.complications}
                                onChange={ctx.handleChange('complications')}
                                type="area" editActive={editModeActive}
                            />
                        }
                        {/* Critical Definitions Table - Deep Topic */}
                        {(isDeepDive && ctx.data?.criticalDefinitions?.length > 0) &&
                            <OverviewRowModule
                                index='criticalDefinitions'
                                label={text.criticalDefinitions}
                                type='other'>
                                <CriticalDefinitionsTable
                                    editActive={editModeActive}
                                />
                            </OverviewRowModule>
                        }
                        {/* Stakeholder Table - Deep Topic */}
                        {isDeepDive &&
                            <OverviewRowModule
                                index='stakeholderTable'
                                label={text.stakeholders}
                                type='other'
                                breakAlways
                            >
                                <StakeholdersTable
                                    editActive={editModeActive}
                                />
                            </OverviewRowModule>
                        }
                        {/* Problem Rating - Deep Topic */}
                        {isDeepDive &&
                            <OverviewRowModule
                                index='rating'
                                label={text.rating}
                                type='other'
                            >
                                <RatingFlagGrid
                                    isOverview
                                    // TODO
                                    editActive={editModeActive}
                                />
                            </OverviewRowModule>
                        }
                        {/* Key Results & Metric - Deep Topic */}
                        {(isDeepDive && ctx.data?.keyResults?.length > 0) &&
                            <OverviewRowModule
                                index='keyResults'
                                label={text.keyResults}
                                type='other'>
                                <KeyResults
                                    isOverview
                                    editActive={editModeActive}
                                />
                            </OverviewRowModule>
                        }
                        {/* Constraints - Deep Topic */}
                        {(isDeepDive && ctx.data?.constraints?.length > 0) &&
                            <OverviewRowModule
                                index='constraints'
                                label={text.constraints}
                                type='other'>
                                <ConstraintsTable editActive={editModeActive} />
                            </OverviewRowModule>
                        }
                    </div>
                </FormContainer>
            </div>
        </div>
    );
}

TopicDefinitionOverview.propTypes = {
    currentCommunity: PropTypes.object,
    getProblemById: PropTypes.func.isRequired,
    updateProblemById: PropTypes.func.isRequired,
    deleteProblemById: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    deleteFile: PropTypes.func.isRequired,
    problem: PropTypes.object
};

const mapStateToProps = (state) => ({
    problem: state.problemReducer.problem,
    profile: state.profileReducer.profile,
    currentCommunity: state.communityReducer.currentCommunity
});

export default connect(mapStateToProps, {
    getProblemById,
    updateProblemById,
    deleteProblemById,
    uploadFile,
    deleteFile
})(TopicDefinitionOverview);