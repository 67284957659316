import { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import TextareaAutosize from "react-textarea-autosize";
import { useTranslation } from "react-i18next";

import FormContainer from "components/FormContainer";
import Taglist from 'components/Taglist/Taglist';
import Tooltip from "components/Tooltip";
import LabelWithSmarttips from 'components/LabelWithSmarttips';
import Input from "components/InputElement";

import TopicContext from "../context/TopicContext"
import VisibilityFlagGrid from "./Components/VisibilityFlagGrid"
import LinkList from "components/LinkList";
import { ButtonAi } from "components/ButtonAi";
import { AiDisplay } from "components/VertexForm";
import MultiSelectBoxMultiLevel from "components/MultiSelectBoxMultiLevel";
import { changeSelectedIdList as changeIdList } from "utils/change-selected-id-list";
import { updateCheckitemState } from "utils/update-checkitem-state";
import { filterValueChains } from 'pages/UserProfilePage/ProfileForm';
import TopicTypesFlagGrid from "./Components/TopicTypesFlagGrid";
import ToggleElement from "components/ToggleElement";
import FileCommentList from "components/FileCommentList";
import ConfidentialFlagGrid from "./Components/ConfidentialFlagGrid";
import GuideForm from "components/GuideForm";

function ProblemStatementForm({
    handleValidation,
    currentCommunity,
    isDeepTopic }) {
    const { t } = useTranslation(['problemDefinition', 'user', 'common']);
    const text = {
        title: t('1-ps-title', "Title & Type"),
        headline: t('1-ps-hl', "Your Topic"),
        titlePlaceholder: t('1-ps-placeholder', "E.g. 'CO2 reduction in school'"),
        topicTypeInfo: t('1_ps_ttinfo', `Exemplary problem statement: How can we decrease the carbon footprint of our school by at least 25% within 1 year compared to the 2020 baseline?
        \nExemplary use case: An insurer wants to develop an internal search tool helping call center agents reduce response time and improve quality by giving specific, near-time input to advanced customer inquiries.
        \nExemplary POV statement & design goal: A call center agent needs variety w.r.t. to the sentiment and content of customer inquiries because his engagement and thus customer satisfaction will rise with multifaceted work. How then can we increase variety in the work of call center agents?`),
        problemStatement: t('1-ps-prompt', "Topic & State"),
        problemStatementPlaceholder: "...",
        problemStatementInfo: t('1-ps-info', "How can we decrease the carbon footprint of our school by at least 25% within 1 year compared to the 2020 baseline?”"),
        problemStatementTips: [
            {
                title: t('1-ps-1t', "Specific"),
                description: t('1-ps-1d', "What is the objective you want to reach? E.g., “increase our NPS score”")
            },
            {
                title: t('1-ps-2t', "Measurable & Realistic"),
                description: t('1-ps-2d', "Set a quantitative, realistic objective")
            },
            {
                title: t('1-ps-3t', "Time bound"),
                description: t('1-ps-3d', "By when should it be solved? E.g. “by 2030”, “within next 2 years”")
            },
            {
                title: t('1-ps-4t', "Open"),
                description: t('1-ps-4d', "Avoid providing any solution. Leave room for creativity and innovations")
            },
            {
                title: t('1-ps-5t', "Contributing"),
                description: t('1-ps-5d', "Contributing directly or indirectly to the overall goals of your organization")
            },
            {
                title: t('1-ps-6t', "Inspirational"),
                description: t('1-ps-6d', "Your problem statement should motivate a team to get the problem solved")
            },
        ],
        visibilityTile: t('1-ps-visibilityTitel', 'Visibility'),
        confidentialInfo: t('1-ps-visibilityTooltip', 'Define who is allowed to read and comment on your input'),
        confidentialTitle: t('1-ps-conf', 'Confidentiality'),
        tags: t('1-ps-tags', 'Tags'),
        segment: t('area1', 'Market Segment', { ns: 'problemDefinition' }),
        valueChain: t('area2', 'Value Chain', { ns: 'problemDefinition' }),
        LinkList: t('1-ps-linkList', 'Related Links'),
        topicState: [t('1_ps_topicState1', 'Open'), t('1_ps_topicState2', 'Implemented')],
        documents: t('1-ps-docs', 'Related Documents'),
    }
    const guideText = [
        {
            header: t('1-ps-g1h', 'How to formulate a problem statement?'),
            text: t('1-ps-g1t', `A problem statement should be outcome-focused, specific, measurable, realistic, and time-bound. Often, a question form will be used. Leave room for creativity and align with other parts of your organization. \n
                Example: \"How can we decrease the carbon footprint of our school by at least 25% within 1 year compared to the 2020 baseline?\"`)
        },
        {
            header: t('1-ps-g2h', 'How to formulate a use case?'),
            text: t('1-ps-g2t', `Tell on a high level who is going to use the solution, what it will be used for, and how users are going to use it. \n
                Example: \"An insurer wants to develop an internal search tool helping call center agents reduce response time and improve quality by giving specific, near-time input to advanced customer inquiries\"`)
        },
        {
            header: t('1-ps-g3h', 'How to formulate a POV statement & design goal?'),
            text: t('1-ps-g3t', `Tell who the user persona is, what she needs and provide insights why. Then ask a question leaving room for creativity, but targeted specifically at the design goal. \n
                Example: \"A call center agent needs variety w.r.t. to the sentiment and content of customer inquiries because his engagement and thus customer satisfaction will rise with multifaceted work. How then can we increase variety in the work of call center agents?\"`)
        },
        {
            header: t('1-ps-g4h', 'How to formulate a research question?'),
            text: t('1-ps-g4t', `Be focused, feasible, novel, relevant, systematic, interesting; consider ethical aspects.`)
        },
        {
            header: t('1-ps-g5h', 'How to formulate an idea?'),
            text: t('1-ps-g5t', `Be focused, feasible, novel, relevant, systematic, interesting; consider ethical aspects.`)
        },
        {
            header: t('1-ps-g6h', 'What does the visibility of a topic mean?'),
            text: t('1-ps-g6t', `Define who is allowed to read an comment on your input. You can choose multiple communities to share your input; these can be within or beyond your organization. Therefore, please be cautious.`)
        },
        {
            header: t('1-ps-g7h', 'Why confidentiality levels?'),
            text: t('1-ps-g7t', `Confidentiality levels are an instrument to ensure the safeguard of personal data and Intellectual Property rights including classified information like business secrets. The inappropriate disclosure of confidential information can lead to physical, material or non-material damage for individuals or legal entities, e.g., where the processing may give rise to financial loss, damage to the reputation or competitive position, or any other significant economic or social disadvantage`)
        },
        {
            header: t('1-ps-g8h', 'How are confidentiality levels connected to data protection regulations?'),
            text: t('1-ps-g8t', `Protection measures of personal data, Intellectual Property rights or other classified information like business secrets are often overlapping in practice, while the legal frameworks differ.`)
        },
        {
            header: t('1-ps-g09h', 'When is the confidentiality level \"strictly confidential\" applicable?'),
            text: t('1-ps-g09t', `This classification is applicable to strictly confidential information and/or special categories of personal data (Art. 9 GDPR), protected by a highly restricted access policy applying to a strictly defined number of persons listed by name only. The inappropriate disclosure to a third party is prohibited. The inappropriate disclosure could reasonably be expected to cause exceptionally grave damage. Examples: Special categories of personal data, passwords`)
        },
        {
            header: t('1-ps-g10h', 'When is the confidentiality level \"confidential\" applicable?'),
            text: t('1-ps-g10t', `This classification is applicable to confidential information and/or personal data (Art. 9 GDPR), protected by a restricted access policy applying to a defined group of individuals. The inappropriate disclosure to a third party is prohibited. The inappropriate disclosure could reasonably be expected to cause grave to medium damage. Examples: personal data, product development documentations`)
        },
        {
            header: t('1-ps-g11h', 'When is the confidentiality level \"internal\" applicable?'),
            text: t('1-ps-g11t', `This classification is applicable to internal information and/or personal data (Art. 9 GDPR), protected by a simple access policy. The inappropriate disclosure to a third party is prohibited. The inappropriate disclosure could reasonably be expected to cause light damage. Example: internal regulations`)
        },
        {
            header: t('1-ps-g12h', 'When is the confidentiality level \"public\" applicable?'),
            text: t('1-ps-g12t', `This classification insights applicable to public information or information meant to be published. There are no handling restrictions. The inappropriate disclosure could reasonably be expected to cause no or no significant damage. Example: marketing flyer`)
        },
        {
            header: t('1-ps-g13h', 'What are the consequences resulting from the confidentiality level?'),
            text: t('1-ps-g13t', `Depending on the likelihood of the damage materializing, the classified information needs to be protected by technical and organisational measures to ensure a level of security appropriate to the risk. Risks can arise from accidental or unlawful destruction, loss, alteration, unauthorised disclosure, or access to personal data transmitted, stored or otherwise processed (cf. Art. 32 (2) GDPR)`)
        },
        {
            header: t('1-ps-g14h', 'Why define market segments?'),
            text: t('1-ps-g14t', `Market segments help to clarify to which industries and industry subsets the topic applies. It then helps to identify relevant experts and further resources in the respective field as well as synergy potentials`)
        },
        {
            header: t('1-ps-g15h', 'Why define value chain segments?'),
            text: t('1-ps-g15t', `Value chain segments help to compare activities across industries and geographies according to their nature. It then helps to identify relevant experts and further resources in the respective field as well as synergy potentials. E.g., a solution working well in insurance pricing might work as well in utilities pricing`)
        },
    ];
    const ctx = useContext(TopicContext);
    const [guideActive, setGuideActive] = useState(false);
    const [industryList, setIndustryList] = useState([])
    const [segmentList, setSegmentList] = useState([]);
    const [valueChainList, setValueChainList] = useState([]);
    const [vertexActive, setVertexActive] = useState(false);
    const [isTouched, setIsTouched] = useState({ title: false, statement: false });

    const [isValid, setIsValid] = useState({
        title: false,
        statement: false,
        visibility: false,
        linkInput: true,
    });

    useEffect(() => {
        if (ctx.data) {
            setIsValid({
                title: !!ctx.data.title,
                statement: !!ctx.data.statement,
                visibility: !!ctx.data.visibility,
                linkInput: true,
            });
        }
    }, [ctx.data]);

    // updates internal segmentList with the checked objects
    useEffect(() => {
        if (ctx.segmentListData?.length > 0) {
            const newSegmentList = ctx.segmentListData.map(item => updateCheckitemState(item, ctx.data.segmentSelected || []));
            setSegmentList(newSegmentList);
            setIndustryList(newSegmentList.filter(item => item?.checked).map(item => item?.industry));
        }
    }, [ctx.segmentListData, ctx.data.segmentSelected]);

    // updates internal valueChainList with the checked objects
    // filter Value Chains either based on selected parents in Segments or previously selected Value Chains 
    useEffect(() => {
        let filteredList = filterValueChains(ctx.valueChainListData, ctx.data.valueChainSelected, industryList);
        const newValueList = filteredList.map(item => updateCheckitemState(item, ctx.data.valueChainSelected || []));
        setValueChainList(newValueList);
    }, [ctx.valueChainListData, industryList, ctx.data.valueChainSelected]);

    const handleBlur = (field) => (e) => {
        setIsTouched((prev) => ({
            ...prev,
            [field]: !e.target.value,
        }));
    };

    // updates the context data and the validation for the fields 
    const changeAndUpdateValidation = (event, fieldName) => {
        ctx.itemHandler(fieldName, event.target.value)
        setIsValid({ ...isValid, [fieldName]: event.target.value?.length > 0 })
    }

    const handleVisibilityValid = (state) => {
        setIsValid({ ...isValid, visibility: !state })
    }

    // Form is invalid while link isn't added to list
    const handleUrlInputActive = (state) => {
        setIsValid({ ...isValid, linkInput: !state })
    }

    const handleStateChange = (id, stateIsClosed) => {
        ctx.itemHandler('isOpen', stateIsClosed)
    }

    useEffect(() => {
        const allValid = Object.values(isValid).every(Boolean);
        if (allValid) {
            handleValidation(true, 'statement');
        } else {
            handleValidation(false, 'statement');
        }
    }, [isValid]);

    // updates formData with new Checked id 
    const changeSegmentList = (item, checked) => {
        ctx.itemHandler('segmentSelected', changeIdList(ctx.data.segmentSelected, ctx.segmentListData, item, checked))
    };

    const changeValueChainList = (item, checked) => {
        ctx.itemHandler('valueChainSelected', changeIdList(ctx.data.valueChainSelected, ctx.valueChainListData, item, checked))
    };

    const baseStyle = `shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md`
    const requiredInfo = <span title={t("This field is required", { ns: 'common' })} className="text-red-600 px-1">*</span>

    const closeAiForm = () => {
        setVertexActive(false)
    }

    return (
        <div id="problemStatementForm">
            <GuideForm guideContent={guideText} active={guideActive}
                onClose={() => setGuideActive(!guideActive)}
                defaultOpen={false}
                width={'w-72 xl:w-1/4 2xl:w-1/3'} />
            <AiDisplay active={vertexActive} context={ctx.data.statement} handleClose={closeAiForm} />
            <FormContainer
                title={text.headline}
                guide={isDeepTopic}
                openGuide={() => setGuideActive(!guideActive)}
                >
                {/* Title Input */}
                <div>
                    <label htmlFor={text.title}
                        className="block text-base font-medium text-gray-700">
                        {text.title}
                        {requiredInfo}
                    </label>
                    <div className="mt-1">
                        <input
                            id="ProblemTitleInput"
                            type="text"
                            name={text.title}
                            className={`${isTouched.title && !ctx.data.title ? 'border-red-500' : ''} ${baseStyle}`}
                            placeholder={text.titlePlaceholder}
                            value={ctx.data.title}
                            onChange={(e) => changeAndUpdateValidation(e, 'title')}
                            onBlur={handleBlur("title")}
                            autoFocus
                        />
                    </div>
                </div>
                {/* Topic Types */}
                <div className="z-20">
                    <TopicTypesFlagGrid
                        tooltip
                        hoverActive={true}
                        editActive={true}
                        activeFlag={ctx.data?.type}
                    />
                </div>
                {/* Problem Statement, Textarea */}
                <div>
                    <Tooltip content={text.problemStatementInfo} />
                    <LabelWithSmarttips id="problemStatementTips" title={text.problemStatement} tips={text.problemStatementTips} required />
                    <div className="mt-1 relative">
                        <TextareaAutosize
                            type="text"
                            minRows="3"
                            name="problemStatement"
                            id="problemStatement"
                            className={`${isTouched.statement && !ctx.data.statement ? 'border-red-500' : ''} ${baseStyle}`}
                            placeholder={text.problemStatementPlaceholder}
                            value={ctx.data.statement}
                            onChange={(e) => changeAndUpdateValidation(e, 'statement')}
                            onBlur={handleBlur("statement")}
                        />
                        <ButtonAi onClick={() => setVertexActive(!vertexActive)} />
                    </div>
                    <div className="mt-2 mb-4">
                        <ToggleElement
                            rightLabel={ctx.data.isOpen ? text.topicState[0] : text.topicState[1]}
                            firstColor={'bg-yellow-500'}
                            secondColor={'bg-green-500'}
                            activated={!ctx.data.isOpen}
                            toggleState={handleStateChange}
                        />
                    </div>
                    <div>

                    </div>
                </div>
                {/* Note  TODO further insight field */}
                {/* <div className="transform -translate-y-4">
                    <label>Further Insights</label>
                    <div className="mt-1 relative">
                        <TextareaAutosize
                            type="text"
                            minRows="3"
                            name="problemStatement"
                            id="problemStatement"
                            className={`${baseStyle} `}
                            placeholder={text.problemStatementPlaceholder}
                            value={ctx.data.statement}
                            onChange={(e) => changeAndUpdateValidation(e, 'statement')}
                            onBlur={handleBlur("statement")}
                        />
                    </div>
                </div> */}
                {/* Attached Documents */}
                {currentCommunity?.config?.isProblemDocuments &&
                    <div>
                        <label htmlFor={'Documents'}
                            className="block text-base font-medium text-gray-700">
                            {text.documents}
                        </label>
                        <FileCommentList
                            editMode
                            documentList={ctx.data.documentList}
                            setTempFiles={(data) => ctx.itemHandler('documentList', data)}
                        />
                        {/* Note TODO Add Documents hold module */}
                    </div>}
                {/* List of Links */}
                {currentCommunity?.config?.isProblemLinks &&
                    <div>
                        <label htmlFor={'LinkList'}
                            className="block text-base font-medium text-gray-700">
                            {text.LinkList}
                        </label>
                        <LinkList
                            editMode
                            list={ctx.data.linkList}
                            changeList={(data) => ctx.itemHandler('linkList', data)}
                            urlInputActive={handleUrlInputActive}
                        />
                    </div>
                }
                {/* Visibility Level */}
                <div>
                    <Tooltip content={text.confidentialInfo} />
                    <label htmlFor='VisibilityFlagGrid' className="block text-base font-medium text-gray-700">
                        {text.visibilityTile}
                        {requiredInfo}
                    </label>
                    <VisibilityFlagGrid
                        hoverActive={true}
                        editActive={true}
                        activeFlag={ctx.data.visibility}
                        isSelected={handleVisibilityValid}
                    />
                </div>
                {/* Confidential Level, only in deep topics */}
                {/* TODO include in overview */}
                {isDeepTopic &&
                    <div>
                        <Tooltip content={text.confidentialInfo} />
                        <label htmlFor='VisibilityFlagGrid' className="block text-base font-medium text-gray-700">
                            {text.confidentialTitle}
                        </label>
                        <ConfidentialFlagGrid
                            hoverActive={true}
                            editActive={true}
                            activeFlag={ctx.data.confidentialLevel}
                        />
                    </div>
                }
                {currentCommunity?.config?.isProblemSegments && segmentList.length > 0 &&
                    <Input
                        id={'interestsSegment'}
                        label={text.segment}
                        other={true}
                        labelClass={`pb-1 block`}
                    >
                        <MultiSelectBoxMultiLevel
                            list={segmentList}
                            updateList={changeSegmentList}
                            name={'interestsSegment'}
                        />
                    </Input>
                }
                {currentCommunity?.config?.isProblemValueChains && valueChainList.length > 0 &&
                    <Input
                        id={'interestsValueChain'}
                        label={text.valueChain}
                        other={true}
                        labelClass={`pb-1 block`}
                    >
                        <MultiSelectBoxMultiLevel
                            list={valueChainList}
                            updateList={changeValueChainList}
                            name={'interestsValueChain'}
                        />
                    </Input>
                }
                {currentCommunity?.config?.isProblemTags &&
                    <div>
                        <label htmlFor='Taglist' className="block text-base font-medium text-gray-700 mb-1">
                            {text.tags}
                        </label>
                        <Taglist
                            value={ctx.data.tags}
                            onChange={(data) => ctx.itemHandler('tags', data)}
                        />
                    </div>
                }
            </FormContainer>
        </div>
    );
}

ProblemStatementForm.propTypes = {
    segments: PropTypes.array,
    valueChains: PropTypes.array,
    currentCommunity: PropTypes.object
};

const mapStateToProps = state => ({
    segments: state.segmentReducer.segments,
    valueChains: state.valueChainReducer.valueChains,
    currentCommunity: state.communityReducer.currentCommunity
});


export default connect(mapStateToProps, null)(ProblemStatementForm)