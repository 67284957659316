// hooks/usePercentage.js
import { useMemo } from 'react';

const usePercentage = (base, value) => {
    return useMemo(() => {
        if (parseInt(base) === 0) return 100;
        return Math.round((value / base) * 100);
    }, [base, value]);
};

export default usePercentage;
