const emptyTopicState = {
    data: {
        visibility: null,
        confidentialLevel: null,
        title: null,
        type: null,
        statement: null,
        criticalDefinitions: [
            {
                id: 1,
                term: null,
                meaning: null,
                commentary: null,
            }
        ],
        isOpen: true,
        documentList: [],
        linkList: [],
        situation: null,
        complications: null,
        stakeholders: [
            {
                id: 1,
                stakeholder: null,
                influence: null,
                affectedness: null,
                needs: null,
                reasoning: null,
            }
        ],
        definitionOfDone: null,
        keyResults: [
            {
                id: 1,
                keyResult: null,
                comment: null,
                quantifiable: false,
                coreMetric:
                {
                    id: 1,
                    baselineDescription: null,
                    baselineValue: null,
                    unit: null,
                    target: null,
                    deltaTarget: null,
                    companyGoal: null,
                    companyGoalPercent: 100,
                },
            }
        ],
        constraints: [
            {
                id: 1,
                constraint: null,
                level: null,
            },
        ],
        communitiesSelected: [],
        segmentSelected: [],
        valueChainSelected: [],
        tags: [],
        createdBy: {
            userId: null,
            firstname: null,
            lastName: null,
            username: null
        }
    },
    segmentListData: [],
    valueChainListData: []
}

export { emptyTopicState };