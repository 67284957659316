import ElementWithHoverinfo from "components/ElementWithHoverinfo";
import { faAnchorCircleCheck, faAnchor } from "@fortawesome/free-solid-svg-icons";

function DeepDiveIcon({ isDeepDive, onClick, tooltipRight, className }) {
    return (
        <ElementWithHoverinfo
            id='deepDiveIcon'
            active
            animateOnChange={true}
            icon={isDeepDive ? faAnchorCircleCheck : faAnchor}
            onClick={!isDeepDive ? onClick : null}
            iconColor={`${isDeepDive ? 'text-primary-500' : 'text-gray-400 hover:text-primary-600'}`}
            tooltipWidth={isDeepDive ? 'min-w-[120px]' : 'min-w-[140px]'}
            tooltip={isDeepDive ? 'Go to Tabview' : 'Transforms a Topic into a Deep Dive'}
            tooltipRight={tooltipRight}
            className={className}
        />
    );
}

export default DeepDiveIcon;