import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "components/Button";
import Steps from "./Steps";
import { useTranslation } from "react-i18next";
import SimpleGroupHover from "./SimpleGroupHover";

/**
 * 
 * Renders a step flow component with a list of steps, current step information,
 * save action, back link, and children components.
 *
 * @param {Object} props - The props object.
 * @param {Array} steplist - The list of all steps needed as an object.
 * @param {string} steplist.name - The name of the step.
 * @param {string} steplist.href - The link to the step.
 * @param {string} steplist.status - The status of the step, e.g. 'current' or 'upcoming'.
 * @param {string} props.currentStep - Sends currentStep information back to parent.
 * @param {function} props.onSave - The action that should be handled on save.
 * @param {string} props.backLink - The link to the page of origin before the step flow started.
 */
function StepFormWrapper({ stepList: initialStepList, currentStep: updateParent, saveNow, ...props }) {
    const { t } = useTranslation();
    const [stepList, setStepList] = useState(initialStepList);
    const [step, setStep] = useState(1);
    const [overviewActive, setOverviewActive] = useState(false);
    const maxSteps = stepList?.length;
    const [isLastStep, setIsLastStep] = useState(step == maxSteps);
    useEffect(() => {
        setStepList(initialStepList)
    }, [initialStepList]);

    const updateStepList = (newStep) => {
        const updatedStepList = stepList.map((s, index) => ({
            ...s,
            isCurrent: index === newStep - 1,
        }));
        setStepList(updatedStepList);
    };

    const nextHandler = () => {
        const newStep = step + 1;
        setStep(newStep);
        updateStepList(newStep);
        if (newStep === maxSteps) {
            setOverviewActive(true);
        }
        updateParent(newStep);
        setIsLastStep(newStep == maxSteps)
    };

    const prevHandler = () => {
        const newStep = step - 1;
        setStep(newStep);
        updateStepList(newStep);
        if (overviewActive) {
            setOverviewActive(false);
        }
        updateParent(newStep);
        setIsLastStep(newStep == maxSteps)
    };

    const onSave = (e) => {
        e.preventDefault(e)
        props.onSave();
    };

    return (
        <div className="h-full flex flex-col justify-between">
            <span className="mt-2">
                <Steps maxSteps={maxSteps} currentStep={step} stepList={stepList} />
            </span>
            {/* Content */}
            <div className="flex-grow overflow-y-auto">{props.children}</div>
            {/* Button Group */}
            <div id={props.buttonGroupId}
                className={`flex justify-between
                ${'w-full max-w-7xl pr-20'}
                z-20 whitespace-nowrap space-x-2 
                pt-1 mb-1`}>
                <Link to={props.backLink}>
                    <Button>
                        {t('Cancel')}
                    </Button>
                </Link>
                <div className='flex space-x-2'>
                    <Button id='buttonBack'
                        onClick={prevHandler}
                        disabled={step === 1 ? true : false}>
                        {t('Back')}
                    </Button>
                    {/* Next button or Save button dependent on the last step, button is disabled when input is invalid */}
                    <SimpleGroupHover
                        active={!props.isValid}
                        text={props.inValidMessage || 'Not all required fields are filled'}
                        position={'bottom-11 right-0'}
                        overwritePosition={true}
                        color={'bg-red-200 text-red-800 border-red-500'}
                        className={'w-96 whitespace-normal'}
                    >
                        {!isLastStep && <Button
                            id={'buttonNext'}
                            onClick={nextHandler}
                            disabled={!props.isValid}
                        >
                            {t('Next')}
                        </Button>}
                        {/* Save Button */}
                        {isLastStep &&
                            <Button
                                className={'ml-8 w-28'}
                                id={'buttonSave'}
                                onClick={onSave}
                                disabled={!props.isValid}
                            >
                                {t('Save')}
                            </Button>
                        }
                        {(saveNow && !isLastStep) &&
                            <Button
                                className={'ml-8 w-28'}
                                id={'buttonSaveNow'}
                                onClick={onSave}
                            >
                                {t('Save Now')}
                            </Button>}
                    </SimpleGroupHover>
                </div>
            </div>
        </div>);
}

export default StepFormWrapper;