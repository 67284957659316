import GuideItem from "./GuideItem";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from "@fortawesome/free-solid-svg-icons";

/**
 * 
 * @param {guideContent, width, defaultOpen, active, onClose} props 
 * @returns 
 */
function GuideForm(props) {
    const guideContent = props.guideContent || [{ header: 'Empty', text: 'This Guide is empty' }];
    const width = props.width || "w-72 2xl:w-96";
    const defaultOpen = props.defaultOpen === undefined ? true : props.defaultOpen;

    return (
        <div className={`${props.active ? 'right-0 overflow-auto' : '-right-full'}
        transition-all  duration-300 ease-in-out
        fixed top-[71px] h-[calc(100vh-71px)] ${width} overflow-auto
        bg-white border-l border-gray-300 shadow-xl z-50`}>

            {/* Header Section */}
            <div className="flex items-center justify-between bg-white h-8 px-4 border-b border-gray-300 cursor-pointer"
                onClick={props.onClose}>
                <span className="text-gray-600">Guide</span>
                <FontAwesomeIcon className="text-gray-600" icon={faX} />
            </div>

            {/* Spacing under header */}
            <div className="h-2"></div>

            {/* Guide Items */}
            <div className="px-4 pt-2 pb-8 space-y-2">
                {guideContent.length > 0 && guideContent.map((item) => (
                    <GuideItem key={item.header}
                        header={item.header} text={item.text}
                        link={item.link} linkText={item.linkText}
                        defaultOpen={defaultOpen} />
                ))}
            </div>
        </div>
    );
}

export default GuideForm;
