import { loadingActionTypes as types } from 'constants/loading.action.types';

const initialState = (window.Cypress && window.initialState && window.initialState.loadingReducer) || {
  activeLoadings: 0
};

export function loadingReducer(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case types.LOADING_STARTED:
      if (state.activeLoadings >= 0) {
        // normal case
        return {
          activeLoadings: state.activeLoadings + 1,
          lastChange: Date.now()
        };
      }
      else {
        // interruption happened
        return {
          activeLoadings: 1,
          lastChange: Date.now()
        };
      }
    case types.LOADING_FINISHED:
      if (state.activeLoadings >= 1) {
        // normal case
        return {
          activeLoadings: state.activeLoadings - 1,
          lastChange: Date.now()
        };
      } else {
        // interruption happened
        return {
          activeLoadings: 0,
          lastChange: Date.now()
        };
      }
    case types.LOADING_INTERRUPT:
      return {
        activeLoadings: 0,
        lastChange: Date.now()
      };
    default:
      return state;
  }

};
