import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Simple Card with optional Icon at top, 
 * best used for Information or decision making
 * @returns Card with text, optional icon or images 
 * active: is the current card selected?
 * noClick: makes cards static and non-selectable/non-clickable
 * title: title of card
 * text: text/description of card
 * icon: optional icon that is displayed on top of the card
 * children: any other nested content that needs to be displayed 
 * className: use like normal 'className'
 * justify: for alignment, default is 'justify-center'
 * padding: default is 'px-6 py-2'
 * textAlign: default is 'text-center'
 * databstoggle, databstarget, databsdismiss can be used for handling modals
 */
function Card({ id, title, text, children,
    active, noClick, onMouseEnter, onMouseLeave, onClick,
    databstoggle, databstarget, databsdismiss,
    style, className, justify, padding, textAlign,
    icon }) {

    return (
        <div
            id={id}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            key={title}
            data-bs-toggle={databstoggle}
            data-bs-target={databstarget}
            data-bs-dismiss={databsdismiss}
            style={style}
            className={`flex 
            ${className}
            ${active && 'bg-primary-50 border-primary-300'} 
            ${!active && 'border-gray-200 bg-white'}
            ${justify || 'justify-center'}
            ${!noClick && 'cursor-pointer hover:border-primary-400 '}
            ${padding || 'px-6 py-2'}
            ${noClick ? 'items-center' : 'flex-col focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500'}
            border-2 relative rounded-lg space-y-2 shadow-sm`}>
            {/* icon part */}
            {icon &&
                // <div className={`${active && 'text-gray-800'} ${noClick ? 'mr-4' : ''} text-gray-500 flex justify-center`}>{icon}
                // </div>
                <FontAwesomeIcon icon={icon} className={`${active && 'text-gray-800'} ${noClick ? 'mr-4 w-7 h-7' : 'w-10 h-10'} text-gray-500 mx-auto`}/>
            }
            {/* content part */}
            <div className="flex flex-col flex-grow">
                {title && <label className={`${!noClick && ' cursor-pointer'} text-center font-semibold`}>{title}</label>}
                {text && <p className={`${textAlign || 'text-center'} text-gray-700 whitespace-pre-line`}>{text}</p>}
                {children}
            </div>
        </div>
    );
}

export default Card;