import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import EditableTable from "components/EditableTable";
import TopicContext from "pages/CreateTopicPage/context/TopicContext";

import StakeholderRow from './StakeholderRow';

function StakeholdersTable(props) {
    const { t } = useTranslation(['problemDefinition']);

    const stakeholderTableHeader = [
        { name: 'stakeholder', private: false, text: t('sh-table-1', 'Stakeholder') },
        { name: 'influence', private: false, text: t('sh-table-2', 'Influence') },
        { name: 'affected', private: false, text: t('sh-table-3', 'Affectedness & Interest') },
        { name: 'need', private: false, text: t('sh-table-4', 'Needs') },
        // { name: 'reasoningAndComments', private: false, text: t('sh-table-5', 'Reasoning & Comments') },
        props.editActive && { name: '', text: t('') }, // empty header cell for delete column
    ]

    const ctx = useContext(TopicContext)
    const tableData = ctx.data.stakeholders;
    const [tableInfoActive, setTableInfoActive] = useState(false);

    useEffect(() => {
        if(tableData.length === 0)
        newRowHandler()
    }, []);

    const toggleInfo = () => {
        setTableInfoActive(!tableInfoActive);
    }

    //TODO FIX #86bzqm0xj
    const newRowHandler = () => {
        ctx.newRowHandler('stakeholders');
    }

    return (
        <div className="relative">
            <EditableTable editActive={props.editActive} tableHeader={stakeholderTableHeader} 
                onClick={toggleInfo}
                addRow={newRowHandler}>
                {tableData && tableData.map((item, index) => (
                    <StakeholderRow
                        editActive={props.editActive}
                        index={index}
                        key={item.id} />
                ))}
            </EditableTable>
        </div>
    );
}

export default StakeholdersTable;