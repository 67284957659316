import { useState, useEffect } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import { useTranslation } from "react-i18next"
import { TAGS_WORDS } from './Tagwords';
// import './style.css';

/**
 * 
 * @param {value, onChange}  
 * @returns Tag input and List of Tags
 * onChange - function that expects a data object of tags 
 */
function Taglist({ value, onChange }) {
    const { t } = useTranslation(['common']);
    const suggestions = TAGS_WORDS.map(item => {
        return {
            id: item,
            text: item
        };
    });
    const KeyCodes = {
        comma: 188,
        enter: 13
    };
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    const [tags, setTags] = useState(value);

    useEffect(() => {
        if (value) {
            setTags(value)
        } else {
            setTags([])
        }
    }, [value]);

    const handleDelete = i => {
        const filteredTags = tags.filter((tag, index) => index !== i);
        setTags(filteredTags);
        onChange(filteredTags);
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
        onChange([...tags, tag]);
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setTags(newTags);
        onChange(newTags);
    };

    const handleTagClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };

    const baseText = 'text-base font-medium text-gray-700'

    // const suggestionsDiv = <div>{suggestions.map((suggestion) => <span>{suggestion.text}</span>)}</div>

    return (
        <div>
            <ReactTags
                tags={tags}
                suggestions={suggestions}
                renderSuggestion={({ text }, query) => <div className=''>{text} ({query})</div>}
                delimiters={delimiters}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleDrag={handleDrag}
                handleTagClick={handleTagClick}
                inputFieldPosition="bottom"
                placeholder={t('Press enter to add new tag')}
                autofocus={false}
                autocomplete={true}
                classNames={{
                    tags: '',
                    tagInput: `${baseText}`,
                    tagInputField: 'mb-1 rounded-md focus:ring-primary-500 border-gray-300 w-full',
                    selected: '',
                    tag: `${baseText} border bg-primary-100 border-primary-200 rounded-md mx-1 py-1 px-2 leading-9 whitespace-nowrap`,
                    remove: 'ml-1 text-gray-700',
                    suggestions: `${baseText}`,
                }}
            />
        </div>
    );
}

export default Taglist;