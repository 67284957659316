import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import CoreMetricsForm from "./CoreMetricsForm";
import { useContext, useState } from "react";
import TopicContext from "pages/CreateTopicPage/context/TopicContext";
import { XIcon } from '@heroicons/react/outline'
import EditableTable from "components/EditableTable";
import EditableRow from "components/EditableRow";

/**
 * Key Result table with key result, comment and checkbox for quantification
 * displays metric form whenever the key result is quantifiable 
 */
function KeyResultTable({ index, isOverview, editActive, }) {
    // TODO define Namespace of translation
    const { t } = useTranslation(['problemDefinition']);
    const ctx = useContext(TopicContext)
    const tableName = 'keyResults'
    const thisRow = ctx.data?.keyResults[index];
    const oneRow = ctx.data.keyResults?.length === 1;
    const tableHeader = [
        { name: 'keyResult', text: t('1-kr-hl', 'Key Result'), private: false },
        { name: 'comment', text: t('Comment', { ns: 'common' }) },
        { name: 'quantifiable', text: t('1_kr_quan', 'Quantifiable?'), style: 'pr-12' },
    ]

    const rowData = [
        { id: 0, name: 'keyResult', value: thisRow.keyResult, type: 'area' },
        { id: 1, name: 'comment', value: thisRow.comment, type: 'area' },
        { id: 2, name: 'quantifiable', value: thisRow.quantifiable, type: 'checkbox', style: 'pl-8 w-10' }
    ]

    const handleInputChange = (rowIndex, name, value, id) => {
        if (name === 'quantifiable' && !thisRow.coreMetric) {
            // If quantifiable is true, add or preserve coreMetric object
            const coreMetric = {
                id: 1,
                baselineDescription: null,
                baselineValue: null,
                unit: null,
                target: null,
                deltaTarget: null,
                companyGoal: null,
                companyGoalPercent: 100,
            };
            ctx.rowHandler('keyResults', 'coreMetric', rowIndex, coreMetric);
        }
        ctx.rowHandler('keyResults', name, rowIndex, value);
    };

    const deleteHandler = () => {
        ctx.deleteRowHandler(tableName, thisRow.id)
    }

    return (
        <>
            <div className="relative">
                <EditableTable tableHeader={tableHeader}>
                    <EditableRow
                        editActive={editActive}
                        oneRow={true}
                        rowIndex={index}
                        rowData={rowData}
                        changeHandler={handleInputChange}
                        noDelete
                    />
                </EditableTable>
                {!oneRow && editActive &&
                    <div className=' group text-center cursor-pointer absolute top-[18px] right-2' onClick={deleteHandler}>
                        <XIcon className='w-5 h-5 m-auto group-hover:text-primary-600 text-gray-500 group-hover:scale-125' />
                    </div>}
            </div>
            {thisRow.quantifiable && < div className="mt-2">
                <CoreMetricsForm
                    keyResultIndex={index}
                    isOverview={isOverview}
                    editActive={editActive} />
            </div >}
        </>
    );
}

export default KeyResultTable;