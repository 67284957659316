import React from 'react';

function Container({ id, children, maxW, className }) {
    return (
        <div id={id} className={`${className} ${maxW ? maxW : 'max-w-7xl'} sm:p-6 `}>
            <div className="w-full">{children}</div>
        </div>
    );
}

export default Container;