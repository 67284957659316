import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FormContainer from "components/FormContainer";
import ProblemTypeSvg from './PROBLEM_TYPES.svg'
import ProblemTypesOutcomeSvg from './MODUL_1_STEP_3_OBEN.svg'
import Modal from "components/Modal";
import Button from "components/Button";
import RatingFlagGrid from "./RatingFlagGrid";
import GuideForm from "components/GuideForm";

function RatingForm() {
    const { t } = useTranslation(['problemDefinition']);
    const [guideActive, setGuideActive] = useState(false);
    const text = {
        headline: t('1-pr-hl', "Problem Rating"),
        description: t('1-pr-desc', "How would you rate your problem?"),
    }

    //TODO English Guide is missing
    const guideText = [
        { 
            header: t('1-pr-g1h', 'How to rate a problem, use case, POV statement, ...?'), 
            text: t('1-pr-g1t', 'You can orientate yourself along the dimensions of uncertainty about future environmental states (solution space) and uncertainty about the current environmental state (the context including known and unknown stakeholder requirements)') 
        },
        { 
            header: t('1-pr-g2h', 'Why rate a problem, use case or POV statement?'), 
            text: t('1-pr-g2t', 'Determining the nature of the problem or use case is decisive for the subsequent choice of method (e.g., Scrum does not need to be used for complicated problems)') 
        },
    ]

    const modalButton1 = <a id="downloadProblemTypeImage" href={ProblemTypesOutcomeSvg} download='ProblemTypes1'>
        <Button>{t('Download')}</Button>
    </a>
    const modalButton2 = <a id="downloadProblemTypeImage" href={ProblemTypeSvg} download='ProblemTypes2'>
        <Button>{t('Download')}</Button>
    </a>

    return (
        <div className="flex flex-col lg:flex-row w-full">
            <Modal id='problemTypes' size='modal-xl'
                buttons={modalButton1} backdrop>
                <img src={ProblemTypesOutcomeSvg} />
            </Modal>
            <Modal id='problemImage' size='modal-xl'
                buttons={modalButton2} backdrop>
                <img src={ProblemTypeSvg} />
            </Modal>
            <GuideForm guideContent={guideText} active={guideActive} onClose={() => setGuideActive(!guideActive)} />
            <FormContainer
                guide={true}
                openGuide={() => setGuideActive(!guideActive)}
                title={text.headline}
                width="lg:w-full w-full">
                <div className="">
                    {/* Cardgrid Area */}
                    <RatingFlagGrid editActive />
                </div>
            </FormContainer>
            {/* Image Area */}
            <div className="flex-grow flex sm:flex-col md:flex-row lg:flex-col lg:ml-20 w-[90%] lg:w-[45%]">
                <button className="cursor-zoom-in" data-bs-toggle='modal' data-bs-target='#problemTypes'>
                    <img src={ProblemTypesOutcomeSvg} />
                </button>
                <button className="cursor-zoom-in" data-bs-toggle='modal' data-bs-target='#problemImage'>
                    <img src={ProblemTypeSvg} />
                </button>
            </div>
        </div>
    );
}

export default RatingForm;