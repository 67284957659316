import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';
import { useTranslation } from "react-i18next";
import { createComment } from 'actions/comment.create.actions';
import { listComments } from 'actions/comments.list.actions';
import BulletPointLabel from 'components/BulletPointLabel';

const valueClassName = 'w-full'

function OverviewRowModule({ breakAlways, ...props }) {
    const { t } = useTranslation(['common']);
    const editActive = props.editActive;
    const hasListMarkers = props.value?.length > 1 && /^\s*[-·]/gm.test(props.value);

    const [parentWidth, setParentWidth] = useState(0);

    const parentRef = useRef(null);

    useEffect(() => {
        if (parentRef.current) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    setParentWidth(entry.contentRect.width);
                }
            });
            resizeObserver.observe(parentRef.current);
            return () => resizeObserver.disconnect();
        }
    }, []);

    const isNarrow = parentWidth < 768 || breakAlways;

    const requiredInfo = <span title={t("This field is required", { ns: 'common' })} className="text-red-600 px-1">*</span>

    return (
        <div ref={parentRef}
            className={`mb-1 ${!editActive && 'hover:bg-primary-50'}`}
            style={{ display: 'flex', flexDirection: isNarrow ? 'column' : 'row' }}>
            {/* Label Area */}
            <div id='row-label-div'
                className={`${isNarrow ? 'w-full' : 'w-1/4'} font-bold p-1 mt-4 md:pt-3 md:px-4 md:mt-0 text-base md:font-small md:font-normal text-gray-600 select-none`}
            >
                <label className=''>{props.label}{props.required && editActive && requiredInfo}</label></div>
            {/* Content Area */}
            <div id='row-content-div'
                className={`${isNarrow ? 'w-full pl-6' : 'w-3/4'} pr-2`}>
                {props.type === 'area' &&
                    <div className='w-auto py-2'>
                        {editActive
                            ? <TextareaAutosize
                                id={props.name}
                                readOnly={!editActive}
                                onChange={props.onChange}
                                className={`${valueClassName} ${(props.required && !props.value) && 'border-red-500'} 
                                border-1 border-gray-300 rounded focus:border-1 focus:bg-primary-100
                                resize-none  mt-1 px-1 pt-2 pb-1`}
                                value={props.value || ''}
                                placeholder={props.placeholder || '...'}
                            >
                            </TextareaAutosize>
                            : <div className={`${valueClassName} border-white whitespace-pre-wrap`}>
                                {hasListMarkers
                                    ? <BulletPointLabel text={props.value} />
                                    : <span className={props.className}>{props.value}</span>
                                }
                            </div>
                        }
                    </div>
                }
                {(props.type === 'table' || props.type === 'other') &&
                    <div className='py-2'> {props.children} </div>
                }
            </div>
        </div>
    );
}


OverviewRowModule.propTypes = {
    createComment: PropTypes.func.isRequired,
    listComments: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    profile: state.profileReducer.profile
});

export default connect(mapStateToProps, { createComment, listComments })(OverviewRowModule);
// export default OverviewRowModule;