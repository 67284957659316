import React from 'react';

// import emptyProblemState from './ProblemProvider'

const TopicContext = React.createContext({
    // data: emptyProblemState.data,
    nextHandler: () => {},
    prevHandler: () => {},
    handleChange: (name, e) => {},
    handleObjectChange: (objectName, name, data) => {},
    itemHandler: (name, data) => {},
    openOverview: () => {},
    idListHandler: (listName, id) => {},
    newRowHandler: (tableName) => {},
    rowHandler: (tableName, name, index, data) => {},
    deleteRowHandler: (tableName, id) => {},
})

export default TopicContext;