import React from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import TopicProvider from './context/TopicProvider';
import NewTopicForm from './NewTopicForm'
import DeepTopicForm from './DeepTopicForm';

function CreateTopicPage() {
    const location = useLocation();
    const isNewTopic = location.pathname.includes('topics/new');
    const isNewProblem = location.pathname.includes('problems/new');
    const problemId = location.search.replace('?topicId=', '');

    return (
        <>
            <TopicProvider problemId={null}>
                {isNewTopic && <NewTopicForm />}
            </TopicProvider>
            <TopicProvider problemId={problemId}>
                {isNewProblem && <DeepTopicForm />}
            </TopicProvider>
        </>
    );
}


export default CreateTopicPage;