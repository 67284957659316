import React from 'react';
import { useTranslation } from "react-i18next";

import FormContainer from "components/FormContainer";
import Tooltip from "components/Tooltip";
import LabelWithSmarttips from 'components/LabelWithSmarttips';

import ConstraintsTable from './ConstraintsTable';


function ConstraintsForm() {
    const { t } = useTranslation(['problemDefinition']);
    const text = {
        headline: t("1-c-hl", "Constraints"),
        tooltip: t("1-c-tooltip", `While a key result follows the rule “more (or less) is better”, a constraint needs to be respected, and if not, the achieved results might not count. 
        A constraint might be compared to a boundary on the racecourse, or to a rule in football. `),
        description: t("1-c-prompt", "Constraints to the solution space you need to take into account"),
        tipList: [
            { title: t("1-c-1t", "Coherent"), description: t("1-c-1d", "No Key Results: “More (or less) is better” does not apply") },
            { title: t("1-c-2t", "Minimalistic"), description: t("1-c-2d", "Focus your constraint to the bare acceptable minimum to ensure a wide solution space") },
            { title: t("1-c-3t", "Verifiable"), description: t("1-c-3d", "The constraint as such and its binding level can be verified (e.g., by asking a stakeholder or checking the statute book)") },
        ]
    }
    return (
        <FormContainer title={text.headline}
            width="xl:w-2/3 md:w-[90%] w-full">
            <div className=''>
                <Tooltip content={text.tooltip} />
                <LabelWithSmarttips
                    id="constraintsTips" title={text.description}
                    tips={text.tipList} />
                <ConstraintsTable editActive={true}/>
            </div>
        </FormContainer>
    );
}

export default ConstraintsForm;