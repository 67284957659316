import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// Props: itemList (Simple Array), selected, style (for width)
function SelectMenu({ selected, onChange, ...props }) {
    const width = (props.style || 'w-40')
    const itemList = props.itemList;
    // const [selected, setSelected] = useState(props.selected || itemList[0])

    return (
        <Listbox value={selected} onChange={onChange}>
            {({ open }) => (
                <>
                    <div className="my-1 relative">
                        <Listbox.Button
                            className={`${width} bg-white relative  border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default 
                        focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm`}>
                            <span className="block truncate">{selected}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {itemList.map((item) => (
                                    <Listbox.Option
                                        key={item}
                                        className={({ active, selected }) =>
                                            classNames(
                                                selected ? 'bg-primary-200 text-white' : '',
                                                active ? 'bg-primary-600 text-white font-bold' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-3'
                                            )
                                        }
                                        value={item}
                                    >
                                        <span className="block truncate">
                                            {item}
                                        </span>
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}

export default SelectMenu;
