import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TooltipBase from "./TooltipBase";
import Button from "./Button";

/**
 * Displays a button or an icon with a hover information box below
 */
function ElementWithHoverinfo({ icon, button, buttonContent, bold, tooltip, active, modal, number, id, onClick, iconColor = 'text-primary-700', animateOnChange, tooltipWidth = 'w-auto max-w-[160px]', tooltipRight, className }) {
    const [hoverActive, setHoverActive] = useState(false);
    const [animation, setAnimation] = useState('opacity-100');

    // useEffect(() => {
    //     if (animateOnChange) {
    //         setAnimation('transform scale-50');
    //         const timer = setTimeout(() => {
    //             setAnimation('transform scale-100');
    //         }, 10);
    //         return () => clearTimeout(timer);
    //     }
    // }, [icon, animateOnChange]);

    const position = tooltipRight ? 'translate-x-[100px] -translate-y-6' : 'translate-y-6'

    return (
        <div
            id={'elementWithHoverInfo_' + id}
            className={`inline-flex justify-center relative ${className}`}
            data-bs-toggle={modal && 'modal'}
            data-bs-target={active && modal}
        >
            <div className={`transition-all duration-200 ${animation}`}>
                {icon && <FontAwesomeIcon
                    className={`${active ? iconColor + ' cursor-pointer' : "text-gray-400"} h-5 transition-opacity duration-500 `}
                    icon={icon}
                    onMouseEnter={() => setHoverActive(true)}
                    onMouseLeave={() => setHoverActive(false)}
                    onClick={onClick}
                />}
                {button &&
                    <span
                        onMouseEnter={() => setHoverActive(true)}
                        onMouseLeave={() => setHoverActive(false)}>
                        <Button id={'button_' + id} bold={bold}>
                            {buttonContent}
                        </Button>
                    </span>
                }
                {active && number &&
                    <span className="absolute -translate-y-3 text-xs bg-primary-100 text-primary-700 rounded-full px-1 ">
                        {number}
                    </span>}
            </div>
            {tooltip && hoverActive &&
                <TooltipBase
                    width={tooltipWidth}
                    className={`${icon ? position : 'translate-y-9'} absolute z-20`}
                    text={tooltip} />
            }
        </div>
    );
}

ElementWithHoverinfo.propTypes = {
    icon: PropTypes.object,
    button: PropTypes.bool,
    bold: PropTypes.bool,
    buttonContent: PropTypes.any,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    active: PropTypes.bool,
    modal: PropTypes.string,
    number: PropTypes.number,
    id: PropTypes.string,
    onClick: PropTypes.func,
    iconColor: PropTypes.string,
    animateOnChange: PropTypes.bool
}

export default ElementWithHoverinfo;
