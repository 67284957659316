import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

function CollapsableDiv({ collapsed = false, header, children, className }) {
    const [isCollapsed, setIsCollapsed] = useState(collapsed)

    return (
        <div id='collapsableDiv' className={`${className}`}>
            <div className={`bg-gray-50 hover:bg-primary-100 text-gray-800 w-full 
        p-1 cursor-pointer
        rounded-t flex justify-between 
        ${isCollapsed && 'rounded-b'}`}
                onClick={() => setIsCollapsed(!isCollapsed)}
                style={{ userSelect: 'none' }}>
                <label className="text-sm text-gray-600 ml-2"
                >{header}
                </label>
                <FontAwesomeIcon icon={isCollapsed ? faChevronDown : faChevronUp} className={'my-auto mr-2'} />
            </div>
            {!isCollapsed &&
                <div id='collapsableOpenDiv'
                    className='relative p-2 border border-gray-200 rounded-b'>
                    {children}
                </div>
            }
        </div>
    );
}

export default CollapsableDiv;