import { useState } from "react";
import PropTypes from 'prop-types';
import Flag from "./Flag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

/**
 * 
 * @param {collapseId, text, flagColor, 
 * flagBorderColor, hiddenBorderColor} props 
 * Important: collapseId cannot start with a number!
 * @returns Flag component that can collapse another item
 * on the same page
 * This component needs the classname 'collapsable' 
 * and the same 'collapseId'
 * For example:
 * <div className='collapse' id={collapseId}></div>
 */
function FlagForCollapsableContent({ collapseId, text, flagColor, disabled, showChevron }) {
    const [contentOpen, setContentOpen] = useState(false);

    const displayText = <p>{showChevron && <FontAwesomeIcon icon={contentOpen ? faChevronUp : faChevronDown} className="pr-1" />}{text}</p>

    return (
        <div className="inline-block">
            {disabled ?
                <Flag
                    color={flagColor || "bg-gray-200"}
                    flagProps={{ borderColor: 'border-gray-200' }}
                    cursor={'cursor-default'}
                    textSize={'text-sm'}
                >
                    {displayText}
                </Flag>
                :
                <a data-bs-toggle="collapse"
                    href={'#' + collapseId}
                    role="button"
                    aria-controls={collapseId}
                    aria-expanded="false">
                    <Flag
                        color={flagColor || "bg-primary-200"}
                        textSize={'text-sm'}
                        flagProps={
                            { borderColor: contentOpen ? 'border-primary-500' : 'border-primary-200' }
                        }
                        onClick={() => setContentOpen(!contentOpen)}
                    >
                        {displayText}
                    </Flag>
                </a>}
        </div>);
}

FlagForCollapsableContent.propTypes = {
    collapseId: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    flagColor: PropTypes.string,
    disabled: PropTypes.bool,
    showChevron: PropTypes.bool,
}

export default FlagForCollapsableContent;