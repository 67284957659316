import { useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import EditableTable from "components/EditableTable";
import TopicContext from 'pages/CreateTopicPage/context/TopicContext';

import ConstraintsRow from './ConstraintsRow';

function ConstraintsTable(props) {
    const { t } = useTranslation(['problemDefinition']);
    const text = {
        tableHeader: [
            t("1-c-table1", "Constraint"),
            t("1-c-table2", "Binding Level"),
            t("1-c-table3", "Comment"),
            ""]
    }
    const ctx = useContext(TopicContext);
    const tableData = ctx.data.constraints;
    
    useEffect(() => {
        if(tableData.length === 0)
        newRowHandler()
    }, []);


    const newRowHandler = () => {
        ctx.newRowHandler('constraints');
    }



    return (
        <EditableTable tableHeader={text.tableHeader} addRow={newRowHandler} editActive={props.editActive} width={'min-w-full'}>
            {tableData && tableData.map((item, index) => (
                <ConstraintsRow
                    editActive={props.editActive}
                    index={index}
                    data={tableData[index]}
                    key={item.id}
                />
            ))}
        </EditableTable>
    );
}

export default ConstraintsTable;